import React from "react";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import ContactForm from "./ContactForm";
const arr = [
  {
    content:
      " NuLark Solutions delivers custom software solutions tailored to meet the unique needs of businesses across various industries.",
    icon: "",
    heading: "Across Countries",
  },
  {
    content:
      " NuLark Solutions expertise extends to IT managed services, ensuring seamless operations and optimal performance for clients' IT infrastructure.",
    icon: "",
    heading: "Across Countries",
  },
  {
    content:
      "NuLark Solutions is committed to providing top-notch services that drive growth and success for businesses worldwide",
    icon: "",
    heading: "Across Countries",
  },
];
export default function Contactpage() {
  return (
    <Container maxWidth="xxl" className="my-5 py-5">
      <Grid container spacing={{ xs: 1, md: 5 }}>
        <Grid item xs={12} md={6}>
          <Box sx={{ px: { xs: 0, md: 7 }, pb: 5 }} className=" w-100 h-100">
            <Box sx={{ position: "relative", m: 3 }} className="w-100 h-100">
              <Box
                sx={{
                  position: "absolute",
                  zIndex: -1,
                  background: "var(--color-yellow)",
                  mt: "12px",
                  ml: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0.75,
                  borderRadius: 3,
                }}
              ></Box>
              <Box
                sx={{
                  position: "absolute",
                  zIndex: -2,
                  background: "var(--color-yellow)",
                  ml: "-12px",
                  mt: "24px",
                  p: 2,
                  width: "100%",
                  height: "100%",
                  opacity: 0.5,
                  borderRadius: 3,
                }}
              ></Box>
              <Box
                sx={{
                  width: 300,
                  height: 300,
                  bgcolor: "var(--color-yellow)",
                  position: "absolute",
                  left: -50,
                  top: -50,
                  opacity: 0.5,
                  borderRadius: "50%",
                  filter: "blur(100px)",
                  zIndex: -5,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  margin: "24px 12px",
                  background: "white",
                  p: 5,
                  boxShadow: "0 10px 30px #0d6efd1f, 0 10px 30px #0d6efd1f",
                  borderRadius: 3,
                }}
              >
                <ContactForm />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="d-flex flex-column gap-5 pt-5 px-5">
            <Typography variant="h5" component="h5" className="fw-bold">
              In a single connected space, you can align, collaborate, and
              increase work visibility.
            </Typography>
            {arr.map(({ content, icon, heading }, index) => {
              return (
                <Box key={index} className="d-flex gap-3">
                  <Box className="d-flex flex-column gap-1">
                    <PublicIcon sx={{ color: "#5d5d5d" }} />
                    <Typography
                      variant="caption"
                      component="h6"
                      className="text-center"
                    >
                      {heading}
                    </Typography>
                  </Box>
                  <Divider
                    style={{ backgroundColor: "grey" }}
                    orientation="vertical"
                    flexItem
                  ></Divider>
                  <Typography variant="subtitle1" component="p">
                    {content}
                  </Typography>
                </Box>
              );
            })}
            {/* <Typography
              variant="caption"
              component="p"
              sx={{ alignSelf: "start" }}
              mb={3}
            >
              For general questions visit our <a href="/"> Help Center</a>
            </Typography> */}
          </Box>
          <Box
            className="d-flex flex-column gap-3 mt-5 rounded"
            sx={{ background: "var(--color-yellow)", p: 3, mx: 3 }}
          >
            <Typography
              variant="subtitle1"
              component="p"
              className="text-center"
            >
              “ A leading provider of numerous services, NuLark Solutions offers
              various products & solutions. Businesses can achieve seamless
              operations, cutting-edge digital experiences, and increased growth
              .”
            </Typography>
            {/* <Typography variant="caption" component="p">
              Stefana Muller | Senior Director, CTO Product and Program Office |
              Oscar
            </Typography> */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider
            style={{ backgroundColor: "grey", marginTop: 30 }}
            flexItem
          ></Divider>
        </Grid>
      </Grid>
    </Container>
  );
}
