import { CheckCircle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, TextField, Typography } from "@mui/material";
import * as React from "react";
import { FormLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";

export default function ContactForm() {
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    jobTitle,
    phone,
    companyName,
    companySize,
    message,
  }) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      to: "nularksolutions@gmail.com",
      subject: `Contacted by ${firstName}`,
      text: `Name: ${firstName} ${lastName},
                 Email: ${email},
                 Training: Full Stack Developer,
                 Phone: ${phone}
                 `,
      html: `
        <table id="customers" style="font-family: Arial, Helvetica, sans-serif;border-collapse: collapse;width: 100%;">
        <tr>
        <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;width:30%">Name</th>
        <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;">Value</th>
      </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Name</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${firstName} ${lastName}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Email</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${email}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Mobile Number</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${phone}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Profession</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${jobTitle}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Comapny</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${companyName}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Size</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${companySize}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Message</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${message}</td>
        </tr>
      </table>
      
        `,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      "https://asia-south1-nulark-blogs.cloudfunctions.net/sendMail",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setResult(true);
        setLoading(false);
        setTimeout(function () {
          setResult(false);
        }, 5000);
      })
      .catch((error) => console.log("error", error));

    reset();
    reset();
  };
  return (
    <Grid
      container
      spacing={2}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <Typography variant="h5" component="h6" mb={3}>
          Contact our Sales team
        </Typography>
      </Grid>
      {result ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckCircle sx={{ color: "#337e36", mr: 1 }} />
          <Typography level="h5" color="success">
            Submitted successfully
          </Typography>
        </Grid>
      ) : null}
      <Grid item xs={12} md={6}>
        <FormLabel>First Name</FormLabel>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          required
          className="w-100"
          InputProps={{
            required: true,
          }}
          {...register("firstName", { required: true })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Last Name</FormLabel>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          required
          className="w-100"
          InputProps={{
            required: true,
          }}
          {...register("lastName", { required: true })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Work Email</FormLabel>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          required
          className="w-100"
          type="email"
          InputProps={{
            required: true,
          }}
          {...register("email", { required: true })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Job Title</FormLabel>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          required
          className="w-100"
          {...register("jobTitle")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Phone Number</FormLabel>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          required
          type="tel"
          className="w-100"
          InputProps={{
            required: true,
          }}
          {...register("phone", { required: true })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Company Name</FormLabel>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          required
          className="w-100"
          InputProps={{
            required: true,
          }}
          {...register("companyName", { required: true })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Company Size</FormLabel>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          required
          className="w-100"
          {...register("companySize")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>How can our team help you?</FormLabel>
        <TextField
          multiline
          rows={3}
          id="outlined-basic"
          label=""
          variant="outlined"
          required
          className="w-100"
          placeholder="Tell us more about your team and what work you'd like to manage with Nulark Solutions"
          InputProps={{
            required: true,
          }}
          {...register("message", { required: true })}
        />
      </Grid>
      <Grid item xs={12} className="d-flex">
        <LoadingButton
          size="normal"
          variant="contained"
          className="rounded-pill px-4 py-2"
          sx={{
            alignSelf: "center",
            bgcolor: "var(--color-yellow) !important",
          }}
          type="submit"
          loading={loading}
        >
          Submit
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
