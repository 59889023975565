import React from "react";
import { Button, keyframes } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
export const slideButton = keyframes`
0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(7px);
            transform: translateX(7px);
  }`;
export default function AnimateButton(props) {
  const navigate = useNavigate();
  const handleNavigate =() => (props.route ? navigate(props.route) : null)
  const handleLink =() => window.open(props.link, "_blank"  , "noopener,noreferrer")
  
  return (
    <>
      <Button
        endIcon={
          <ArrowForwardIcon
            sx={{
              ml: 0.5,
              animation: `${slideButton} 1s ease-in-out infinite alternate both`,
            }}
          />
        }
        sx={{
          background: props.color ? props.color : "#0c0c0c",
          borderRadius: 30,
          px: 3,
          py: 1,
          color: props.textcolor ? props.textcolor : "#fff",
          "&:hover": {
            color: props.textcolor ? props.textcolor : "#fff",
            background: props.color ? props.color : "#0c0c0c",
            boxShadow: "0px 1px 7px #eee",
          },
        }}
        onClick={props.route ? handleNavigate : handleLink}
      >
        {props.title}
      </Button>
    </>
  );
}
