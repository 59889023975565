export const basicPlanFeatures = [
    "Remote Desktop Support: Limited hours per month for end-user assistance.",
    "Basic Network Monitoring: Monitoring essential network components for uptime.",
    "Essential Security Measures: Basic antivirus and firewall protection.",
    "Monthly Health Checks: Regular system health checks.",
    "Documentation Review: Review and basic improvements to existing documentation."
];

export const basicPlanSuitableFor = [
    "Small businesses with minimal IT needs.",
    "Startups on a tight budget.",
    "Those who require occasional support."
];

export const basicPlanFreebies = [
    "Free evaluation of the current IT Infrastructure",
    "Customized pocket friendly quotation - Ticket based",
    "Free File management system (Local implementation / cloud basic hosting management cost)",
    "Monthly 1 health checkup free"
];

export const flexiblePlanFeatures = [
    "Expanded Remote Desktop Support: Extended hours for end-user assistance.",
    "Advanced Network Monitoring: Comprehensive monitoring for network performance.",
    "Enhanced Security Measures: Advanced firewall configurations and threat detection.",
    "Quarterly Security Audits: In-depth security assessments.",
    "Monthly Training Sessions: Regular online training sessions for staff."
];

export const flexiblePlanSuitableFor = [
    "Growing businesses with evolving IT requirements.",
    "Companies with a moderate budget for IT services.",
    "Those needing more proactive support and training."
];


export const premiumPlanFeatures = [
    "24/7 Remote Support: Around-the-clock support for critical issues.",
    "Cloud Services Management: Configuration and management of cloud services.",
    "Advanced Data Backup Solutions: Robust backup and recovery services.",
    "Bi-Annual Security Workshops: In-depth workshops on cybersecurity best practices.",
    "Quarterly IT Assessments: Comprehensive assessments for system optimization."
];

export const premiumPlanSuitableFor = [
    "Established businesses with a significant IT infrastructure.",
    "Organizations that prioritize security and continuous improvement.",
    "Companies with a moderate to high IT budget."
];

export const ultimatePlanFeatures = [
    "Dedicated Account Manager: A dedicated point of contact for personalized service.",
    "Priority Emergency Response: Expedited response time for critical issues.",
    "Advanced IT Policy Review: In-depth review and optimization of IT policies.",
    "Customized Training Programs: Tailored training programs for specific needs.",
    "Monthly Performance Optimization: Continuous optimization of IT systems."
];

export const ultimatePlanSuitableFor = [
    "Larger enterprises with complex IT environments.",
    "Organizations with high-security needs.",
    "Businesses that require extensive customization and support."
];