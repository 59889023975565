import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { slideButton } from "../../Components/container/AnimateButton";

export default function VirtualTourpage() {
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5" sx={{ textAlign: "justify" }}>
        <Typography
          variant="h4"
          component="h5"
          className="my-4 text-center fw-semibold"
        >
          Virtual Tour AR/VR
        </Typography>
        {/* row 1 */}
        <Grid container spacing={0} mb={2}>
          <Grid item xs={12} md={7} clas>
            <Box
              component="img"
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/vr.png"
              className="img-fluid"
              sx={{
                p: { md: 5 },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" component="p">
              Introducing our exceptional virtual tour services, where we bring
              your unique concepts to life! At our company, we understand that
              every client has a distinct vision and requirements when it comes
              to showcasing their spaces or products. That's why we take pride
              in our ability to create custom virtual tours that align perfectly
              with your concept and objectives.
            </Typography>
          </Grid>
        </Grid>
        {/* row 2 */}
        <Grid container spacing={0} mb={2}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="order-md-1 order-2"
          >
            <Typography variant="subtitle1" component="p" className="mt-2">
              Imagine having the power to transport your audience to any
              location, whether it's a stunning resort, a luxurious property, or
              an immersive art exhibition. With our virtual tours, we transform
              your ideas into captivating digital experiences that engage and
              mesmerize your viewers.
            </Typography>
            <Typography variant="subtitle1" component="p" className="mt-2">
              Our team of skilled professionals is dedicated to understanding
              your vision and translating it into a visually stunning and
              interactive virtual tour. We carefully craft every detail,
              ensuring that the colors, textures, lighting, and ambiance
              accurately represent the essence of your space or product.
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} className="order-1 order-md-2">
            <Box
              component="img"
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/vr01.png"
              className="img-fluid"
              sx={{
                p: { md: 5 },
              }}
            />
          </Grid>
        </Grid>

        {/* row 3 */}
        <Grid container spacing={0} mb={2}>
          <Grid item xs={12} md={7}>
            <Box
              component="img"
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/vr03.png"
              className="img-fluid"
              sx={{
                p: { md: 5 },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="subtitle1" component="p" className="mt-2">
              Not only do our virtual tours showcase your offerings in the most
              realistic way possible, but they also provide an immersive
              experience that goes beyond static images or videos. Your audience
              can explore every nook and cranny, interact with objects, and even
              feel like they're physically present in the environment through
              the power of VR and AR technologies.
            </Typography>

            <Typography variant="subtitle1" component="p" className="mt-2">
              Moreover, our commitment to quality means that our virtual tours
              are optimized for various platforms, including web browsers,
              mobile devices, and VR headsets. This allows you to reach a wider
              audience and provide an accessible and engaging experience across
              different devices.
            </Typography>
          </Grid>
        </Grid>
        {/* row 4 */}
        <Grid container spacing={0} mb={2}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              pr: { md: 5 },
            }}
            className="order-md-1 order-2"
          >
            <Typography variant="subtitle1" component="p" className="mt-2">
              We also understand that your concept may evolve or require updates
              over time. That's why our virtual tours are designed with
              flexibility in mind. We provide easy-to-use tools that allow you
              to update content, add new features, or modify the tour to reflect
              changes in your space or product.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="order-md-2 order-1">
            <Box
              component="img"
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/vr02.png"
              className="img-fluid"
              sx={{
                p: { md: 5 },
                borderRadius: 50,
              }}
            />
          </Grid>
        </Grid>

        {/* row 5 */}
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} p={5}>
            <Box className="ratio ratio-16x9" sx={{ overflow: "hidden" }}>
              <iframe
                width="100%"
                src="https://www.youtube.com/embed/xvzXP8JCxvg?autoplay=1&mute=1&loop=1"
                title="video360 1687498817"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              component="p"
              className="mt-2 fw-semibold"
            >
              To enjoy a 360° Virtual Tour, pause the video and simply drag the
              picture to explore each image at your own pace.
            </Typography>
            <Typography variant="subtitle1" component="p" className="mt-2">
              Also discover our extensive collection of immersive 360° Virtual
              Tours, showcasing stunning visuals and unparalleled
              user-friendliness. Explore our impressive demos and experience the
              captivating beauty of our tours firsthand.
            </Typography>
            <Button
              size="small"
              variant="contained"
              className="rounded-pill px-3"
              // href="https://simplevirtualtour.it/app/showcase/all"
              // target="_blank"
              endIcon={
                <ArrowForwardIcon
                  sx={{
                    ml: 0.5,
                    animation: `${slideButton} 1s ease-in-out infinite alternate both`,
                  }}
                />
              }
              sx={{
                mt: 3,
                alignSelf: { xs: "center", md: "start" },
                background: "#0c0c0c",
                borderRadius: 30,
                px: 3,
                py: 1,
                color: "#fff",
                "&:hover": {
                  color: "#fff",
                  background: "#0c0c0c",
                },
              }}
            >
              Explore Now
            </Button>
          </Grid>
        </Grid>

        <Typography variant="subtitle1" component="p" className="mt-5">
          With our tailored virtual tour services, you have the freedom to bring
          your unique concept to life and provide an unforgettable experience
          for your audience. Whether you're in the real estate industry,
          hospitality sector, or art world, we have the expertise and passion to
          deliver virtual tours that exceed your expectations.
        </Typography>
        <Typography variant="subtitle1" component="p" className="mt-2">
          Experience the power of custom virtual tours and let us help you
          showcase your space or product like never before. Contact us today to
          discuss your concept and embark on a journey of immersive digital
          exploration!
        </Typography>
      </Container>
    </Box>
  );
}
