import { Box, Container, Typography } from "@mui/material";
import React from "react";

export default function Aboutpage() {
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5" sx={{ textAlign: "justify" }}>
        <Typography variant="h4" component="h5" className="mt-3">
          About us
        </Typography>
        <Typography variant="subtitle1" component="p" className="mt-3">
          Welcome to our tech startup, where efficiency and creativity meet
          innovation! We are at the forefront of transforming how people and
          businesses interact with the digital world. We are here to transform
          your digital presence and take your company to new heights with our
          expertise in VR and AR solutions, event registration management, and
          dynamic website building.
        </Typography>
        <Typography variant="subtitle1" component="p" className="mt-2">
          Imagine having the ability to discover a place without actually being
          there. With the help of our virtual tour technology, you may
          experience a lifelike experience from the convenience of your own
          device while being immersed in a realistic and interactive 3D
          environment. Our virtual tours will fascinate your audience and leave
          a lasting impression, whether you're a real estate agent selling
          properties or a museum curator showcasing exhibits.
        </Typography>
        <Typography variant="subtitle1" component="p" className="mt-2">
          However, virtual reality is not where we end. Your experiences will be
          enhanced by using our augmented reality solutions. We can incorporate
          digital content into the physical environment using augmented reality
          to create experiences that are both interactive and captivating. Our
          augmented reality systems offer a wide range of opportunities, from
          educational applications to interactive marketing campaigns.
        </Typography>
        <Typography variant="subtitle1" component="p" className="mt-2">
          The process of planning an event might be difficult, but with the help
          of our event registration management system, you can make it easier.
          Our software makes event administration smooth and effective, from
          ticket sales and participant registration through tracking and
          analytics. Bid manual paperwork farewell and welcome to a stress-free
          event planning adventure.
        </Typography>
        <Typography variant="subtitle1" component="p" className="mt-2">
          We understand that a website serves as the online persona of your
          company. For this reason, we created a dynamic website builder that
          makes it simple for you to construct beautiful and responsive
          websites. No knowledge of coding or design is necessary. Your vision
          may come to life as you promote your goods or services using our
          user-friendly interface and editable layouts.
        </Typography>
        <Typography variant="subtitle1" component="p" className="mt-2">
          When it comes to emerging technology, we at our tech startup company
          believe in pushing the envelope. Innovative solutions that will change
          the way you conduct business are what we aim to deliver. As a result,
          we have the skills and enthusiasm to realise your vision, whether it
          be to construct an engaging website, manage your events effectively,
          or create immersive virtual experiences.
        </Typography>
        <Typography variant="subtitle1" component="p" className="mt-2">
          Join us on this thrilling adventure as we transform the digital
          environment and open up new opportunities for your company. Welcome to
          the future, when the impossible is possible and innovation knows no
          bounds.
        </Typography>
      </Container>
    </Box>
  );
}
