import { Grid } from "@mui/material";
import * as React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Option,
  Textarea,
  Button,
  ModalClose,
  Sheet,
  Modal,
  Typography,
  CssVarsProvider,
} from "@mui/joy";
import { useForm } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export default function Form() {
  const [open, setOpen] = React.useState(true);
  const [result, setResult] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async ({
    title,
    firstName,
    lastName,
    email,
    mobileNumber,
    whatsappNumber,
    profession,
    commentsAndQuestions,
    city,
    country,
  }) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      to: "nularksolutions@gmail.com",
      subject: `Contacted by ${firstName}`,
      text: `Name: ${firstName} ${lastName},
                 Email: ${email},
                 Training: Full Stack Developer,
                 Phone: ${mobileNumber}
                 `,
      html: `
        <table id="customers" style="font-family: Arial, Helvetica, sans-serif;border-collapse: collapse;width: 100%;">
        <tr>
        <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;width:30%">Name</th>
        <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;">Value</th>
      </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Name</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${title}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Name</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${firstName} ${lastName}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Email</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${email}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Mobile Number</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${mobileNumber}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">WhatsApp Number</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${whatsappNumber}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Profession</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${profession}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">City</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${city}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Country</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${country}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Message</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${commentsAndQuestions}</td>
        </tr>
      </table>
      
        `,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      "https://asia-south1-nulark-blogs.cloudfunctions.net/sendMail",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setResult(true);
        setLoading(false);
        setTimeout(function () {
          setResult(false);
        }, 5000);
      })
      .catch((error) => console.log("error", error));

    reset();
  };

  const handleTitle = (event, newValue) => {
    register("title", { value: newValue });
  };
  const handleProfession = (event, newValue) => {
    register("profession", { value: newValue });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
        pt:15
      }}
    >
        <CssVarsProvider>
        <Sheet
        variant="soft"
        sx={{
          maxWidth: 700,
          borderRadius: "md",
          boxShadow: "lg",
          p: {xs:3,md:5},
          backgroundColor: "rgba(255,255,255,.5)",
        }}
      >
        <Grid
          container
          spacing={2}
          component={"form"}
          sx={{
            maxWidth: 600,
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {result ? (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckCircleIcon sx={{ color: "#337e36", mr: 1 }} />
              <Typography level="h5" color="success">
                Submitted successfully
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Select
                onChange={handleTitle}
                size="sm"
                color="neutral"
                sx={{
                  backgroundColor: "rgba(255,255,255,.75)",
                }}
                required
              >
                <Option value="Mr">Mr</Option>
                <Option value="Miss">Mrs</Option>
                <Option value="Miss">Miss</Option>
                <Option value="Ms">Ms</Option>
                <Option value="Dr">Dr</Option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input
                placeholder="First Name"
                size="sm"
                {...register("firstName")}
                required
                color="neutral"
                sx={{
                  backgroundColor: "rgba(255,255,255,.75)",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Input
                placeholder="Last Name"
                required
                size="sm"
                {...register("lastName")}
                sx={{
                  backgroundColor: "rgba(255,255,255,.75)",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Email </FormLabel>
              <Input
                placeholder="Email"
                required
                type="email"
                size="sm"
                {...register("email")}
                sx={{
                  backgroundColor: "rgba(255,255,255,.75)",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel>Mobile Number</FormLabel>
              <Input
                required
                placeholder="Mobile Number"
                type="tel"
                size="sm"
                {...register("mobileNumber")}
                sx={{
                  backgroundColor: "rgba(255,255,255,.75)",
                }}
                slotProps={{
                  input: {
                    pattern:"[0-9]*" 

                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel>WhatsApp Number</FormLabel>
              <Input
                required
                placeholder="WhatsApp Number"
                type="tel"
                size="sm"
                {...register("whatsappNumber")}
                sx={{
                  backgroundColor: "rgba(255,255,255,.75)",
                }}
                
                slotProps={{
                  input: {
                    pattern:"[0-9]*" 

                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input
                required
                placeholder="City"
                size="sm"
                {...register("city")}
                sx={{
                  backgroundColor: "rgba(255,255,255,.75)",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel>Country</FormLabel>
              <Input
                required
                placeholder="Country"
                size="sm"
                {...register("country")}
                sx={{
                  backgroundColor: "rgba(255,255,255,.75)",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Select Profession</FormLabel>
              <Select
                required
                onChange={handleProfession}
                size="sm"
                sx={{
                  backgroundColor: "rgba(255,255,255,.75)",
                }}
              >
                <Option value="Working Professional">
                  Working Professional{" "}
                </Option>
                <Option value="Student">Student</Option>
                <Option value="Job Seeker">Job Seeker</Option>
              </Select>
            </FormControl>
          </Grid>
       
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Comments / Any Questions </FormLabel>
              <Textarea
                required
                minRows={3}
                maxRows={5}
                {...register("commentsAndQuestions")}
                sx={{
                  backgroundColor: "rgba(255,255,255,.75)",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              loading={loading}
              loadingIndicator="Submitting…"
              fullWidth
              type="submit"
              color="neutral"
              sx={{
                backgroundColor: "#172535",
              }}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Sheet>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderColor: "white",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
	
            maxWidth: 700,
            //width:"95%",
            borderRadius: "md",
            p: 1,
            boxShadow: "lg",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.surface",
            }}
          />
          <Box
            sx={{
              borderRadius: 9,
              overflow: "hidden",
              display: {xs:'none',sm:'flex'},
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/3+months.png"
              alt="..."
              width={"100%"}
              style={{
                objectFit: "contain",
maxHeight:"90vh"
              }}
            />
          </Box>
          <Box
            sx={{
              borderRadius: 9,
              overflow: "hidden",
              display: {xs:'flex',sm:'none'},
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/StartingSoon.png"
              alt="..."
              width={"100%"}
              style={{
                objectFit: "cover",
              }}
            />
          </Box>
        </Sheet>
      </Modal>
        </CssVarsProvider>
    
    </Box>
  );
}


// <Grid item xs={12}>
// <FormControl>
//   <FormLabel>Select Training</FormLabel>
//   <Select
//     required
//     onChange={handleTraining}
//     size="sm"
//     sx={{
//       backgroundColor: "rgba(255,255,255,.75)",
//     }}
//   >
//     <Option value="The Frontend Developer Career Path">
//       The Frontend Developer Career Path
//     </Option>
//     <Option value="Beginner to Advamced in HTML & CSS">
//       Beginner to Advamced in HTML & CSS
//     </Option>
//     <Option value="Beginner to Advanced in React.js">
//       Beginner to Advanced in React.js
//     </Option>
//     <Option value="Backend With Firebase">
//       Backend With Firebase
//     </Option>
//     <Option value="Figma to Code">Figma to Code</Option>
//   </Select>
// </FormControl>
// </Grid>
// <Grid item xs={12}>
// <FormControl>
//   <FormLabel>Select Training Batch </FormLabel>
//   <Select
//     required
//     onChange={handleTrainingBatch}
//     size="sm"
//     sx={{
//       backgroundColor: "rgba(255,255,255,.75)",
//     }}
//   >
//     <Option value="Morning Batch">Morning Batch</Option>
//     <Option value="Evening Batch">Evening Batch</Option>
//   </Select>
// </FormControl>
// </Grid>