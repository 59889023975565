import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";

export default function WebsiteBuider() {
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5" sx={{ textAlign: "justify" }}>
        <Typography variant="h4" component="h5" className="my-4  fw-semibold">
          Website Builder Tool
        </Typography>
        <Row>
          <Col xs={12} md={6} className="order-md-2">
            <img
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/wb001.gif"
              className="img-fluid"
              alt=".."
              style={{
                mixBlendMode: "multiply",
                objectFit: "cover",
              }}
            />
          </Col>
          <Col xs={12} md={6}>
            <Typography variant="subtitle1" component="p" className="my-2 ">
              <strong>DIY website builder tool</strong> with ongoing upgrades,
              marketing offers, and returns on investment We provide it in a
              novel way, allowing you to select your own front-end design, which
              we will create and deliver within{" "}
              <strong>7 to 10 working days. </strong>
            </Typography>
            <Typography variant="subtitle1" component="p" className="my-2 ">
              That's an interesting approach to offering a website builder tool.
              By allowing users to choose their own frontend design and handling
              the development and delivery within a specific timeframe, you
              provide a unique value proposition. This approach can offer
              convenience and efficiency to users who may not have the time or
              technical skills to create a website from scratch.
            </Typography>
            <Typography variant="subtitle1" component="p" className="my-2 ">
              By providing a customizable frontend design, users can have more
              control over the visual appearance and branding of their websites.
              This flexibility allows them to align the website design with
              their specific preferences and requirements.
            </Typography>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <img
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/wb02.gif"
              className="img-fluid"
              alt=".."
              style={{
                mixBlendMode: "multiply",
                objectFit: "cover",
              }}
            />
          </Col>
          <Col xs={12} md={6}>
            <Typography variant="subtitle1" component="p" className="my-2 ">
              The promise of delivering the developed website within 7 to 10
              working days can be appealing to users who are looking for a quick
              turnaround time. This can be especially beneficial for individuals
              or businesses that need a website up and running promptly, such as
              for launching a new product or promoting an event.
            </Typography>
            <Typography variant="subtitle1" component="p" className="my-2 ">
              In addition to the development aspect, it's important to consider
              the other factors such as constant updates, marketing features,
              offers, and ROI that I mentioned earlier. These elements can
              enhance the overall value and effectiveness of your website
              builder tool, making it more attractive to potential users.
            </Typography>
            <Typography variant="subtitle1" component="p" className="my-2 ">
              To ensure a successful offering, it's crucial to maintain a high
              level of quality in both the frontend design options and the
              development process. Regularly updating and expanding the design
              options can keep your tool competitive and aligned with the latest
              design trends. Additionally, prioritizing ongoing support and
              providing a user-friendly interface can contribute to a positive
              user experience.
            </Typography>
          </Col>
        </Row>
        <Typography variant="subtitle1" component="p" className="my-2 ">
          Marketing efforts, such as promoting the benefits of your unique
          approach, targeting relevant audiences, and showcasing successful
          websites created through your platform, can help attract new users and
          generate interest in your service.
        </Typography>
        <Typography variant="subtitle1" component="p" className="my-2 ">
          Monitoring and analyzing the ROI of your website builder tool can
          provide valuable insights into its performance and allow you to refine
          your offerings and marketing strategies accordingly.
        </Typography>
        <Typography variant="subtitle1" component="p" className="mb-4">
          Remember to adapt and iterate based on user feedback, market trends,
          and evolving customer needs to ensure the long-term success and
          relevance of your website builder tool.
        </Typography>
      </Container>
    </Box>
  );
}
