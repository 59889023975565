import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const tiers = [
  {
    title: "Limited",
    price: "60",
    description: [
      "One VR Tour",
      "Maximum 5 Rooms (Panoramas)",
      "Default Domain",
      "Embedded link",
      "One time configuration and set up",
      "2 Revisions",
    ],
    buttonText: "Start now",
    buttonVariant: "outlined",
    buttonRoute:"/contact"
  },
  {
    title: "Unlimited",
    subheader: "Recommended",
    price: "199",
    description: [
      "Unlimited VR Tours",
      "40+ Pro Feature",
      "10 GB Storage",
      "Default Domain",
      "Access to backend",
      "One time training",
      "Priority email support",
    ],
    buttonText: "Start now",
    buttonVariant: "contained",
    buttonRoute:"/contact"
  },
  {
    title: "Enterprise",
    price: "329+",
    description: [
      "All Features of Unlimited Plan",
      "Dedicated Server",
      "Whitelabel Domain",
      "A.I. Panorama (Addon)",
      "External Storage",
      "Phone & Email Support",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
    buttonRoute:"/contact"
  },
];

export default function Pricing() {
  const navigate = useNavigate();
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          component="h2"
          variant="h3"
          gutterBottom
        >
          Our popular <strong>VR Platform</strong>
        </Typography>
        <Typography variant="subtitle1" component="p">
          Unlock the boundless potential of 3D Virtual Reality (VR) and
          Augmented Reality (AR) with our innovative{" "}
          <Link href="https://virtualtour.nulark.tech/showcase/vr-demos" target="_blank" sx={{ fontWeight: 600 }}>
            Platform
          </Link>
          .
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                border: tier.title === "Unlimited" ? "1px solid" : undefined,
                borderColor:
                  tier.title === "Unlimited" ? "primary.main" : undefined,
                background:
                  tier.title === "Unlimited"
                    ? "linear-gradient(#033363, #021F3B)"
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color:
                      tier.title === "Unlimited" ? "primary.contrastText" : "",
                  }}
                >
                  <Typography
                    component="h3"
                    variant="h6"
                    color={
                      tier.title === "Unlimited" ? "primary.contrastText" : ""
                    }
                  >
                    {tier.title}
                  </Typography>
                  {tier.title === "Unlimited" && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === "light" ? "" : "none",
                        backgroundColor: "primary.contrastText",
                        "& .MuiChip-label": {
                          color: "primary.dark",
                        },
                        "& .MuiChip-icon": {
                          color: "primary.dark",
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    color:
                      tier.title === "Unlimited"
                        ? "primary.contrastText"
                        : undefined,
                  }}
                >
                  <Typography
                    component="h3"
                    variant="h2"
                    color={
                      tier.title === "Unlimited" ? "primary.contrastText" : ""
                    }
                  >
                    ${tier.price}
                  </Typography>
                  <Typography
                    component="h3"
                    variant="h6"
                    color={
                      tier.title === "Unlimited" ? "primary.contrastText" : ""
                    }
                  >
                    &nbsp; per month
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === "Unlimited"
                            ? "primary.light"
                            : "primary.main",
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === "Unlimited"
                            ? "primary.contrastText"
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"
                 onClick={()=> navigate(tier.buttonRoute)}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
