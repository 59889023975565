import * as React from "react";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// icons
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
// components
import CustomTabs from "./CustomTabs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navbarState, setSelectedNavItem } from "./reduxSlice";

// styles for accordion
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #eee`,

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: "#fff",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const drawerWidth = "100%";

const Pages = [
  // {
  //   name: "Solutions",
  //   route: "/solutions",
  // },
  // {
  //   name: "Resources",
  //   route: "",
  // },
    {
    name: "Online Training",
    route: "/onlinetraining",
  },
  {
    name: "About us",
    route: "/about",
  },
  {
    name: "Contact Sales",
    route: "/contact",
  },
];
function DrawerAppBar() {
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  const [isOpen, setIsOpen] = React.useState(false);
  const { tabData, selectedNavItem } = useSelector(navbarState);

  const navigate = useNavigate();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (tabName, tabData) => {
    if (selectedNavItem.data && isOpen && selectedNavItem.tabName === tabName) {
      dispatch(
        setSelectedNavItem({
          tabName: "",
          data: "",
        })
      );
      setIsOpen(false);
    } else {
      dispatch(
        setSelectedNavItem({
          tabName: tabName,
          data: tabData,
        })
      );
      setIsOpen(true);
    }
  };
  React.useEffect(() => {
    dispatch(
      setSelectedNavItem({
        tabName: "",
        data: "",
      })
    );
    setIsOpen(false);
    // eslint-disable-next-line
  }, [navigate]);

  const drawer = (
    <Box sx={{ textAlign: "left" }}>
      <Box className="d-flex justify-content-between align-items-center">
        <Box
          component="img"
          src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/logo.png"
          alt="NuLark Solutions"
          className="img-fluid"
          height={50}
          my={2}
          onClick={() => {
            navigate("/");
            setMobileOpen(false);
          }}
        ></Box>
        <IconButton onClick={handleDrawerToggle} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      {tabData.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === "panel" + index + 1}
          onChange={handleChange("panel" + index + 1)}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            sx={{
              "& .MuiAccordionSummary-expandIconWrapper": {
                display: item.data ? "block" : "none",
              },
            }}
          >
            <Typography variant="subtitle1" component="p">
              {item.tabName}
            </Typography>
          </AccordionSummary>
          {item.data ? (
            <AccordionDetails>
              {item.data?.map((item, index) => (
                <Typography
                  key={index}
                  variant="subtitle2"
                  component="p"
                  className="mb-1 ps-2"
                  onClick={() => {
                    navigate(item.path);
                    setMobileOpen(false);
                  }}
                >
                  {item.title}
                </Typography>
              ))}
            </AccordionDetails>
          ) : null}
        </Accordion>
      ))}
      <Box className="d-flex flex-column justify-content-center ">
        {Pages.map(({ name, route }, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              pl: 1.4,
            }}
            divider
            onClick={() => {
              navigate(route);
              setMobileOpen(false);
            }}
          >
            <ListItemButton>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        ))}
      </Box>
    </Box>
  );
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          component="nav"
          sx={{
            py: 1,
            background: "#fff",
            boxShadow: "0 5px 13px #0d6efd1f",
          }}
        >
          <Toolbar>
            <Box
              component="img"
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/logo.png"
              alt="NuLark Solutions"
              className="img-fluid"
              height={50}
              sx={{ display: { md: "none" } }}
              my={2}
              onClick={() => {
                navigate("/");
                setMobileOpen(false);
              }}
            ></Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml: "auto", display: { md: "none" } }}
            >
              <MenuIcon sx={{ color: "#000" }} />
            </IconButton>

            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                  gap: 3,
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <Box
                component="img"
                src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/logo.png"
                alt="NuLark Solutions"
                className="img-fluid"
                sx={{
                  cursor: "pointer",
                  // mixBlendMode: "multiply",
                }}
                height={50}
                onClick={() => {
                  navigate("/");
                  // window.scroll({
                  //   top: 0,
                  //   behavior: "smooth",
                  // });
                }}
                onMouseOver={() => {
                  dispatch(
                    setSelectedNavItem({
                      tabName: "",
                      data: "",
                    })
                  );
                  setIsOpen(false);
                }}
              ></Box>
              {tabData.map((item) => (
                <Button
                  variant="text"
                  className="hover"
                  key={item.tabName}
                  endIcon={
                    item.data ? (
                      selectedNavItem.tabName === item.tabName &&
                      selectedNavItem.data &&
                      isOpen ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : null
                  }
                  sx={{
                    textTransform: "capitalize",
                    py: 1,
                    m: 0,
                    fontWeight: 400,
                    fontSize: "13px !important",
                    color:
                      item.tabName === selectedNavItem.tabName &&
                      selectedNavItem.data &&
                      isOpen
                        ? "var(--color-yellow)"
                        : "#535768",
                    background:
                      item.tabName === selectedNavItem.tabName &&
                      selectedNavItem.data &&
                      isOpen
                        ? "#ffd10611 !important"
                        : "#FFF",
                  }}
                  onClick={() => {
                    dispatch(
                      setSelectedNavItem({
                        tabName: item.tabName,
                        data: item.data,
                      })
                    );
                    handleClick(item.tabName, item.data);
                  }}
                  // onMouseOver={() => {
                  //   dispatch(
                  //     setSelectedNavItem({
                  //       tabName: item.tabName,
                  //       data: item.data,
                  //     })
                  //   );
                  // }}
                >
                  {item.tabName}
                </Button>
              ))}
              {Pages.slice(0, Pages.length - 1).map(
                ({ name, route }, index) => (
                  <Button
                    key={index}
                    variant="text"
                    className="hover"
                    sx={{
                      textTransform: "capitalize",
                      py: 0.5,
                      m: 0,
                      fontWeight: 400,
                      fontSize: "13px !important",
                      color: "#535768",
                    }}
                    onClick={() => navigate(route)}
                  >
                    {name}
                  </Button>
                )
              )}
            </Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                flexGrow: 1,
                justifyContent: "end",
              }}
            >
              <Button
                variant="text"
                className="hover"
                startIcon={<CallRoundedIcon />}
                sx={{
                  textTransform: "capitalize",
                  py: 0.5,
                  m: 0,
                  fontWeight: 400,
                  fontSize: "13px !important",
                  color: "#535768",
                }}
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Contact Sales
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>

      <Box
        className="view"
        sx={{
          display: selectedNavItem.data && isOpen ? "flex" : "none",
          position: "fixed",
          top: 30,
          width: "100%",
          height: "94vh",
          background: "#fff",
          opacity: 0.6,
          zIndex: 12,
          backdropFilter: "blur(200px)",
        }}
      ></Box>
      {/* drop down */}
      <Box
        className="view"
        sx={{
          display: selectedNavItem.data && isOpen ? "flex" : "none",
          position: "fixed",
          width: "100%",
          height: "80%",
          background: "#FFF",
          zIndex: 13,
          mt: 10,
          pb: 2,
          overflowY: "scroll",
        }}
      >
        <CustomTabs selectedTab={selectedNavItem.tabName} />
      </Box>
    </>
  );
}

export default DrawerAppBar;
