import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Col, Row } from "react-bootstrap";

export default function CustomDynamicWebsite() {
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5" sx={{ textAlign: "justify" }}>
        <Typography variant="h4" component="h5" className="my-4  fw-semibold">
          Custom Dynamic Website
        </Typography>

        <Row className="mx-0 p-0">
          <Col item xs={12} md={5} className="order-md-2 h-100 d-flex">
            <img
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/webApp.gif"
              alt=".."
              style={{
                mixBlendMode: "multiply",
                my: "auto",
              }}
            />
          </Col>
          <Col item xs={12} md={7}>
            <Typography variant="subtitle1" component="p" className="my-2 ">
              <strong>
                Custom design dynamic websites with a website builder
              </strong>{" "}
              offers several advantages and benefits, particularly for
              individuals and businesses without coding knowledge. Here are some
              reasons why and whom it can be beneficial: Website builders with
              regular updates and no coding knowledge requirements are
              particularly beneficial for:
            </Typography>
            <Typography variant="subtitle1" component="li" className="my-2 ">
              Small businesses or startups with limited budgets for web
              development.
            </Typography>
            <Typography variant="subtitle1" component="li" className="my-2 ">
              Individuals or freelancers who want to create personal websites or
              online portfolios.
            </Typography>
            <Typography variant="subtitle1" component="li" className="my-2 ">
              Non-profit organizations or community groups that need an online
              presence but lack technical expertise.
            </Typography>
            <Typography variant="subtitle1" component="li" className="my-2 ">
              Bloggers or content creators who want to establish their online
              presence quickly.
            </Typography>
            <Typography variant="subtitle1" component="li" className="my-2 ">
              E-commerce businesses looking for an easy-to-use platform to sell
              products online.
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              className="mt-4 mb-2 "
            >
              Overall, using a website builder without coding knowledge offers a
              convenient and accessible solution for creating dynamic websites,
              ensuring regular updates, and providing customization options to
              suit various needs.
            </Typography>
          </Col>
        </Row>

        <Row>
          <Col item xs={12} md={5} className=" h-100 d-flex">
            <img
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/webApp1.gif"
              alt=".."
              style={{
                mixBlendMode: "multiply",
                my: "auto",
              }}
            />
          </Col>
          <Col item xs={12} md={7}>
            <Box className="d-flex flex-column align-items-start gap-1 mt-5">
              <Typography variant="subtitle2" component="p" className="mb-2">
                <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
                <strong> Ease of Use</strong>
              </Typography>
              <Typography variant="subtitle2" component="p" className="mb-4">
                Website builders provide user-friendly interfaces that allow
                non-technical users to create and customize websites without the
                need for coding knowledge. This makes it accessible to
                individuals or small businesses who don't have the resources to
                hire professional web developers.
              </Typography>

              <Typography variant="subtitle2" component="p" className="mb-2">
                <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
                <strong> Cost-Effective</strong>
              </Typography>
              <Typography variant="subtitle2" component="p" className="mb-4">
                Building a website using a website builder is typically more
                cost-effective than hiring a web development agency or
                freelancer. Website builders often have affordable subscription
                plans that include hosting and regular updates.
              </Typography>

              <Typography variant="subtitle2" component="p" className="mb-2">
                <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
                <strong>Time Efficiency</strong>
              </Typography>
              <Typography variant="subtitle2" component="p" className="mb-4">
                Website builders streamline the website development process by
                offering pre-designed templates and drag-and-drop functionality.
                This saves time compared to coding a website from scratch, as
                users can quickly customize the layout, add content, and make
                changes easily.
              </Typography>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col item xs={12} md={5} className="order-md-2 h-100 d-flex">
            <img
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/webApp2.gif"
              alt=".."
              style={{
                mixBlendMode: "multiply",
                my: "auto",
              }}
            />
          </Col>
          <Col item xs={12} md={7}>
            <Box className="d-flex flex-column align-items-start gap-1 mt-5">
              <Typography variant="subtitle2" component="p" className="mb-2">
                <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
                <strong>Customization Options</strong>
              </Typography>
              <Typography variant="subtitle2" component="p" className="mb-4">
                While website builders offer templates, they also provide a
                range of customization options, allowing users to personalize
                their websites to match their brand or individual style. Users
                can customize colors, fonts, images, and layout elements to
                create a unique website.
              </Typography>

              <Typography variant="subtitle2" component="p" className="mb-2">
                <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
                <strong>Regular Updates and Maintenance</strong>
              </Typography>
              <Typography variant="subtitle2" component="p" className="mb-4">
                Website builders typically handle updates and maintenance tasks
                automatically. This ensures that websites are always up-to-date
                with the latest security patches and feature enhancements
                without the user needing to worry about coding or technical
                aspects.
              </Typography>

              <Typography variant="subtitle2" component="p" className="mb-2">
                <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
                <strong>Flexibility and Scalability</strong>
              </Typography>
              <Typography variant="subtitle2" component="p" className="mb-4">
                Website builders offer flexibility in terms of design and
                functionality. Users can add features like contact forms, image
                galleries, e-commerce capabilities, and more, as their needs
                evolve. It allows businesses to start with a basic website and
                scale up as they grow.
              </Typography>

              <Typography variant="subtitle2" component="p" className="mb-2">
                <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
                <strong>DIY Approach</strong>
              </Typography>
              <Typography variant="subtitle2" component="p" className="mb-4">
                Website builders empower individuals or small businesses to take
                a do-it-yourself approach to website creation and management.
                They can have full control over their online presence, update
                content, and make changes whenever necessary without relying on
                external help.
              </Typography>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  );
}
