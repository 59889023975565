import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import { navbarState, setSelectedNavItem } from "./reduxSlice";
import { useNavigate } from "react-router-dom";

export default function CustomTabs(props) {
  const dispatch = useDispatch();
  const { selectedNavItem } = useSelector(navbarState);
  const [data, setData] = React.useState("");
  const navigate = useNavigate();
  React.useEffect(() => {
    setData("");
  }, [selectedNavItem]);

  return (
    <>
      <Grid container spacing={2}>
        {selectedNavItem.data ? (
          <>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  boxShadow: "0 5px 30px #0d6efd1f",
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 5,
                  px: 2,
                  pl: 5,
                  pt: 3,
                  height: "100%",
                }}
              >
                <Divider></Divider>
                <Typography
                  variant="subtitle1"
                  component="p"
                  mt={2}
                  color="#0c0c0c"
                >
                  {selectedNavItem.tabName} by NuLark
                </Typography>
                <List>
                  {selectedNavItem
                    ? selectedNavItem?.data?.map(
                        ({ id, icon, title, desc, items }, index) => {
                          return (
                            <ListItem
                              id={id}
                              disablePadding
                              key={index}
                              onClick={() =>
                                setData({
                                  id: id,
                                  title: title,
                                  desc: desc,
                                  items: items,
                                })
                              }
                              sx={{
                                background:
                                  data.id === id ? "#ffd10611 !important" : "",
                                color:
                                  data.id === id
                                    ? "var(--color-yellow)"
                                    : "inherit",
                              }}
                            >
                              <ListItemButton className="rounded-end">
                                <ListItemIcon
                                  sx={{
                                    minWidth: "auto",
                                    mr: 1.5,
                                    color:
                                      data.id === id
                                        ? "var(--color-yellow)"
                                        : "inherit",
                                  }}
                                >
                                  {icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Box
                                      sx={{
                                        display: "flex !important",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          color:
                                            selectedNavItem.id === id
                                              ? "var(--color-yellow)"
                                              : "inherit",
                                        }}
                                      >
                                        {title}
                                      </Typography>
                                      <KeyboardArrowRightIcon />
                                    </Box>
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        }
                      )
                    : null}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Container maxWidth="xxl" className="pt-3">
                <Box
                  className="d-flex justify-content-between p-3 rounded-1"
                  sx={{
                    "&:hover": {
                      background: "#ffd10611",
                    },
                  }}
                >
                  <div className="d-flex flex-column align-items-start gap-2">
                    <Typography variant="h6" component="p">
                      <strong>NuLark </strong>
                      {data?.title}
                    </Typography>
                    <Typography variant="body1" component="p">
                      {data.desc ? data.desc : selectedNavItem?.data[0].desc}
                    </Typography>
                  </div>

                  <Button
                    size="small"
                    variant="outlined"
                    className="rounded-pill px-3"
                    endIcon={<ArrowForwardIcon sx={{ ml: 0.5 }} />}
                    sx={{
                      textTransform: "capitalize",
                      color: "var(--color-yellow)",
                      border: "1px solid var(--color-yellow) !important",
                    }}
                    onClick={() => {
                      dispatch(
                        setSelectedNavItem({
                          tabName: "",
                          data: "",
                        })
                      );
                      navigate("/contact");
                    }}
                  >
                    Contact Us
                  </Button>
                </Box>

                <Divider></Divider>
                <Box className="d-flex flex-wrap justify-content-between my-3">
                  {data
                    ? data?.items.map(({ title, desc, route }, index) => {
                        return (
                          <Box
                            key={index}
                            className="d-flex flex-column align-items-start p-3"
                            sx={{
                              alignSelf: "normal",
                              width: data?.items.length <= 2 ? "100%" : "48%",
                              cursor: route ? "pointer" : "default",
                              "&:hover": {
                                background: "#ffd10611",
                              },
                            }}
                            onClick={() => (route ? navigate(route) : null)}
                          >
                            <Typography variant="body1" component="p">
                              {title}
                            </Typography>
                            <Box className="d-flex justify-content-between">
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className="pe-5"
                                sx={{ fontWeight: 400 }}
                              >
                                {desc}
                              </Typography>
                              {route ? (
                                <IconButton
                                  className="mt-auto"
                                  size="small"
                                  sx={{
                                    border: "1px solid var(--color-yellow)",
                                    color: "var(--color-yellow)",
                                  }}
                                >
                                  <ArrowForwardIcon sx={{ fontSize: "1rem" }} />
                                </IconButton>
                              ) : null}
                            </Box>
                          </Box>
                        );
                      })
                    : null}
                </Box>
              </Container>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
}
