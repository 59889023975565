import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export default function RMS() {
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5" sx={{ textAlign: "justify" }}>
        <Typography variant="h4" component="h5" className="my-4  fw-semibold">
          Event Registration Management
        </Typography>
        <Typography variant="subtitle1" component="p" className="my-2 ">
          <strong>RMS: An Event Registration Management system </strong>is a
          software solution that helps streamline and automate the process of
          managing event registrations. It offers a range of benefits and
          advantages for both event organizers and attendees. Here are some
          reasons why an Event Registration Management system is beneficial and
          a proposed approach:
        </Typography>

        <Typography variant="subtitle1" component="p" className="my-3 ">
          Benefits of an Event Registration Management System:
        </Typography>
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Efficient Registration Process:</strong> An Event Registration
          Management system simplifies the registration process for attendees by
          providing an online platform where they can easily register and
          provide necessary information. It eliminates the need for manual
          paperwork and reduces the chances of errors or data loss.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Time and Cost Savings: </strong>Automating the registration
          process saves time for both event organizers and attendees. It
          eliminates the need for manual data entry and reduces administrative
          tasks. It also helps organizers save costs on printing registration
          forms, managing physical registration counters, and additional staff.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Enhanced Attendee Experience: </strong>A well-designed
          registration system improves the overall attendee experience.
          Attendees can register at their convenience, access event details,
          choose sessions or workshops, and make payments online. It provides a
          user-friendly interface that enhances satisfaction and engagement.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Data Management and Analytics: </strong>
          An Event Registration Management system collects and stores attendee
          data in a centralized database. This allows organizers to easily
          manage and analyze registration information, generate reports, and
          gain insights into attendee demographics, preferences, and behavior.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Communication and Engagement: </strong>
          The system facilitates effective communication with attendees through
          automated email notifications, reminders, and updates. Organizers can
          send personalized messages, share event-related information, and
          engage with attendees before, during, and after the event.
        </Typography>

        {/* <Row>
          <Col xs={12} md={6} className="order-md-2">
            <img
              src={img1}
              className="img-fluid"
              alt=".."
              style={{
                mixBlendMode: "multiply",
                objectFit: "cover",
              }}
            />
          </Col>
          <Col xs={12} md={6}>
            <Typography variant="subtitle1" component="p" className="my-2 ">
              <strong>DIY website builder tool</strong> with ongoing upgrades,
              marketing offers, and returns on investment We provide it in a
              novel way, allowing you to select your own front-end design, which
              we will create and deliver within{" "}
              <strong>7 to 10 working days. </strong>
            </Typography>
            <Typography variant="subtitle1" component="p" className="my-2 ">
              That's an interesting approach to offering a website builder tool.
              By allowing users to choose their own frontend design and handling
              the development and delivery within a specific timeframe, you
              provide a unique value proposition. This approach can offer
              convenience and efficiency to users who may not have the time or
              technical skills to create a website from scratch.
            </Typography>
            <Typography variant="subtitle1" component="p" className="my-2 ">
              By providing a customizable frontend design, users can have more
              control over the visual appearance and branding of their websites.
              This flexibility allows them to align the website design with
              their specific preferences and requirements.
            </Typography>
          </Col>
        </Row> */}

        <Typography
          variant="h4"
          component="p"
          className="mt-5 pt-3 mb-4 text-start"
        >
          Proposed Approach for an Event Registration Management System
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>User-Friendly Interface</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Develop an intuitive and user-friendly interface that allows attendees
          to register easily. The registration form should capture necessary
          information such as personal details, contact information, session
          preferences, and any additional requirements.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Online Payment Integration</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Integrate a secure payment gateway that enables attendees to make
          payments online during the registration process. Provide multiple
          payment options to accommodate different preferences.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong> Customization and Branding</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Allow event organizers to customize the registration form,
          confirmation emails, and event landing page to reflect their branding.
          This helps create a cohesive and professional experience for
          attendees.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Session and Workshop Selection</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Incorporate a feature that allows attendees to choose sessions or
          workshops they wish to attend. Provide real-time updates on session
          availability to avoid overbooking.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong> Reporting and Analytics</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Develop a reporting module that allows event organizers to generate
          reports on registration statistics, attendee demographics, payment
          summaries, and other relevant data. Provide analytics capabilities to
          derive insights and optimize future events.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Communication and Notifications</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Implement an automated email notification system to send confirmation
          emails, reminders, updates, and other event-related information to
          attendees. Allow organizers to customize email templates and schedule
          automated messages.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Mobile-Friendly Design</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Ensure the system is responsive and mobile-friendly, allowing
          attendees to register and access event information on their
          smartphones or tablets.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong> Data Security and Privacy</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Implement robust security measures to protect attendee data and ensure
          compliance with data protection regulations.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong> Integration and Scalability</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Offer integration options with other event management tools, CRM
          systems, or marketing platforms to enhance functionality. Design the
          system to be scalable, accommodating larger events or multiple events
          simultaneously.
        </Typography>

        <Typography variant="subtitle1" component="p" className="mb-4">
          By implementing an Event Registration Management system with these
          features and considerations, event organizers can streamline the
          registration process, enhance attendee experience, improve data
          management, and save time and costs associated with event registration
          management.
        </Typography>
      </Container>
    </Box>
  );
}
