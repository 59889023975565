import { createSlice } from "@reduxjs/toolkit";

import Diversity2Icon from "@mui/icons-material/Diversity2";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import SchoolIcon from "@mui/icons-material/School";
import VillaIcon from "@mui/icons-material/Villa";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
const initialState = {
  selectedNavItem: "",
  selectedIndex: 0,
  tabData: [
    {
      tabName: "IT Services",
      data: [
        {
          id: 1,
          icon: <SupportAgentIcon />,
          title: "Remote IT Admin Services",
          desc: "Empower Your Business with Seamless Remote IT Admin Services!",
          path: "/remoteservice",
          items: [
            {
              title: "Remote IT Admin Services",
              desc: "Simplify your IT hassle with our Remote Admin Service! Our dedicated team keeps your systems running smoothly 24/7, handling issues and updates from afar...",
              route: "/remoteservice",
            },
          ],
        },
        {
          id: 2,
          icon: <Diversity2Icon />,
          title: "Virtual Tour AR/VR",
          desc: "360° Virtual Tour Services",
          path: "/virtual-tour",
          items: [
            {
              title: "Virtual Tour Services",
              desc: "Introducing our exceptional virtual tour services, where we bring your unique concepts to life! At our company, we understand that every client has...",
              route: "/virtual-tour",
            },
            {
              title: "360° Virtual Tour",
              desc: "Check out our all new virtual tour demo's and explore the beauty of Virtual Tours",
              url: "https://virtualtour.nulark.tech/viewer/nu_virtual_demo",
              route: "/virtual-tour",
            },
            {
              title: "Custom Virtual Tours",
              desc: "Experience the power of custom virtual tours and let us help you showcase your space or product like never before.",
              route: "/virtual-tour",
            },
            {
              title: "Virtual and Task Managemenet",
              desc: "Manage and collaborate on team projects and tasks from one place",
              route: "/virtual-tour",
            },
          ],
        },
        {
          id: 3,
          icon: <ReceiptIcon />,
          title: "Custom Dynamic Websites",
          desc: "Empower Your Creativity ,with us Website Building Made Easy",
          path: "/custom-dynamic-webiste",
          items: [
            {
              title: "Custom Web Applications",
              desc: "Custom design dynamic websites with a website builder offers several advantages and benefits, particularly for individuals and businesses without coding...",
              route: "/custom-dynamic-webiste",
            },
            {
              title: "Website builders with regular update",
              desc: "Website builders with regular updates and no coding knowledge requirements are particularly beneficial for...",
              route: "/custom-dynamic-webiste",
            },
            {
              title: "Customization options to suit various needs",
              desc: "Ease of Use, Cost-Effective, Time Efficiency, Customization Options, Regular Updates and Maintenance, Flexibility and Scalability, DIY Approach",
              route: "/custom-dynamic-webiste",
            },
          ],
        },
        {
          id: 4,
          icon: <DevicesOtherIcon />,
          title: "Custom Web Applications",
          desc: "Unleash Your Business Potential with Custom Web Applications",
          path: "/custom-web-application",
          items: [
            {
              title: "Custom Web Applications",
              desc: "Custom web applications are tailor-made software solutions developed specifically for a business or organization to address their ...",
              route: "/custom-web-application",
            },
            {
              title: "Benefits of Custom Web Applications",
              desc: "Tailored to Specific Needs, Increased Efficiency and Productivity, Scalability and Flexibility, Enhanced User Experience, Integration Capabilities...",
              route: "/custom-web-application",
            },
            {
              title: "Who Would Need Custom Web Applications",
              desc: "Businesses with Unique Processes, Startups and Growing Businesses, Enterprises with Legacy Systems, Service-based Industries, Organizations...",
              route: "/custom-web-application",
            },
          ],
        },
        {
          id: 5,
          icon: <AppShortcutIcon />,
          title: "Custom Mobile Apps",
          desc: "Developed specifically for a business or organization",
          path: "/custom-mobile-app",
          items: [
            {
              title: "Custom Mobile Apps",
              desc: "Custom mobile apps, developed specifically for a business or organization, offer several benefits and can provide...",
              route: "/custom-mobile-app",
            },
            {
              title: "Benefits of Custom Mobile Apps",
              desc: "Tailored User Experience, Improved Efficiency and Productivity, Enhanced Customer Engagement, Enhanced Customer Engagement...",
              route: "/custom-mobile-app",
            },
            {
              title: "ROI of Custom Mobile Apps",
              desc: "Increased Revenue, Cost Savings, Customer Retention and Loyalty, Operational Efficiency, Operational Efficiency..",
              route: "/custom-mobile-app",
            },
          ],
        },
        {
          id: 6,
          icon: <HistoryEduIcon />,
          title: "School ERP",
          desc: "Designed to manage various Administrative and Academic Processes",
          path: "/school-erp",
          items: [
            {
              title: "School Enterprise Resource Planning",
              desc: "A School ERP system is a software solution designed to streamline and manage various administrative and academic processes...",
              route: "/school-erp",
            },
            {
              title: "Requirements of a School ERP System",
              desc: "Student Information Management, Attendance Tracking, Exam and Grading Management, Timetable Management, Fee Management,Communication and Collaboration...",
              route: "/school-erp",
            },
            {
              title: "Benefits of a School ERP System",
              desc: "Improved Efficiency,  Streamlined Processes, Enhanced Communication, Accurate Data Management,Cost and Time Savings..",
              route: "/school-erp",
            },
          ],
        },
        {
          id: 7,
          icon: <SchoolIcon />,
          title: "College and University Evaluation System",
          desc: "Driving Excellence through Evaluation: Empowering Educational Institutions",
          path: "/college-university-evaluation-system",
          items: [
            {
              title: "College and University Evaluation Systems",
              desc: "College and University Evaluation Systems are designed to assess and measure the performance, quality, and effectiveness of educational. These systems serve several important purposes...",
              route: "/college-university-evaluation-system",
            },
            {
              title: "Benefits of Evaluation Systems",
              desc: "Quality Assurance,  Accreditation,  Continuous Improvement,  Accountability and Transparency, Benchmarking and Comparison, Student-Centric Focus...",
              route: "/college-university-evaluation-system",
            },
            {
              title: "Evaluation Systems plays a vital role",
              desc: "college and university evaluation systems play a vital role in ensuring quality education, fostering improvement, promoting accountability, and facilitating informed decision-making for..",
              route: "/college-university-evaluation-system",
            },
          ],
        },
        {
          id: 8,
          icon: <VillaIcon />,
          title: "Institutional ERP solutions",
          desc: "Unlock Growth with ERP: Elevating Education, Attracting Bright Futures",
          path: "/institutional-erp",
          items: [
            {
              title: "Institutional ERP solutions",
              desc: "Manage and collaborate on team projects and tasks from one place ts and tasks from one place",
              route: "/institutional-erp",
            },
            {
              title: "Institutional ERP solutions",
              desc: "Manage and collaborate on team projects and tasks from one place",
              route: "/institutional-erp",
            },
            {
              title: "Institutional ERP solutions",
              desc: "Manage and collaborate on team projects and tasks from one place",
              route: "/institutional-erp",
            },
          ],
        },
      ],
    },
    {
      tabName: "Products",
      data: [
        {
          id: 1,
          icon: <Diversity2Icon />,
          title: "Website Builder Tool",
          desc: "DIY Website Builder with Custom Front-End Design Delivered Fast!",
          path: "/website-builder",
          items: [
            {
              title: "DIY Website Builder Tool",
              desc: " We provide it in a novel way, allowing you to select your own front-end design, which we will create and deliver within 7 to 10 working days ...",
              route: "/website-builder",
            },
            {
              title: "Get Your Website Ready in Just 7-10 Days!",
              desc: "The promise of delivering the developed website within 7 to 10 working days can be appealing to users who are looking for a quick turnaround time. This can be especially...",
              route: "/website-builder",
            },
            {
              title:
                "Maximize Your Marketing Potential, Unleash ROI Insights for Unbeatable Growth",
              desc: "Marketing efforts, such as promoting the benefits of your unique approach, targeting relevant audiences, and showcasing successful websites created ...",
              route: "/website-builder",
            },
          ],
        },
        {
          id: 2,
          icon: <ReceiptIcon />,
          title: "Event Registration Management",
          desc: "Simplify Events, Delight Attendees: RMS - Your Event Registration Solution",
          path: "/registration-management-system",
          items: [
            {
              title: "Event Registration Management",
              desc: "RMS: An Event Registration Management system is a software solution that helps streamline and automate the process of managing event registrations...",
              route: "/registration-management-system",
            },
            {
              title: "Benefits of an Event Registration Management System",
              desc: "Efficient Registration Process, Time and Cost Savings, Time and Cost Savings, Data Management and Analytics, Data Management and Analytics...",
              route: "/registration-management-system",
            },
            {
              title:
                "Proposed Approach for an Event Registration Management System",
              desc: "User-Friendly Interface, Online Payment Integration,  Customization and Branding, Session and Workshop Selection...",
              route: "/registration-management-system",
            },
          ],
        },
        {
          id: 3,
          icon: <AccountTreeIcon />,
          title: "Project Management System (PMS)",
          desc: "Boost your projects with seamless efficiency using PMS",
          path: "/pms",
          items: [
            {
              title: "Project and Task Management System",
              desc: " The well-designed project and task management system NuLark PMS enables your customers to create and manage projects...",
              route: "/pms",
            },
            {
              title: "Features of PMS",
              desc: "User Interface Dashboard, Role & Permissions Management, User Management, Client Management, Projects and its team members Management...",
              route: "/pms",
            },
            {
              title: "PMS for engineering and construction",
              desc: "In the context of engineering and construction, a PMS typically offers features and functionalities specific to the industry's requirements...",
              route: "/pms",
            },
          ],
        },
        {
          id: 4,
          icon: <AppShortcutIcon />,
          title: "Fundraising Application - Comming Soon...",
          items: [
            {
              title: "Comming Soon...",
              desc: "",
            },
          ],
        },
        {
          id: 5,
          icon: <HistoryEduIcon />,
          title: "Advance File Manager- Comming Soon...",
          items: [
            {
              title: "Comming Soon...",
              desc: "",
            },
          ],
        },
        {
          id: 6,
          icon: <SchoolIcon />,
          title: "POS Retail Manager -Comming Soon...",
          items: [
            {
              title: "Comming Soon...",
              desc: "",
            },
          ],
        },
        {
          id: 7,
          icon: <VillaIcon />,
          title: "VR Software- Comming Soon...",
          items: [
            {
              title: "Comming Soon...",
              desc: "",
            },
          ],
        },
        {
          id: 8,
          icon: <DevicesOtherIcon />,
          title: "Club or Society activity Application -Comming Soon...",
          items: [
            {
              title: "Comming Soon...",
              desc: "",
            },
          ],
        },
      ],
    },
    {
      tabName: "Non-IT services",
      data: [
        {
          id: 1,
          icon: <Diversity2Icon />,
          title: "AP custodian -Comming Soon...",
          items: [
            {
              title: "Comming Soon...",
              desc: "",
            },
          ],
        },
        {
          id: 2,
          icon: <ReceiptIcon />,
          title: "eCommerce Portal -Comming Soon...",
          items: [
            {
              title: "Comming Soon...",
              desc: "",
            },
          ],
        },
      ],
    },
    {
      tabName: "Rental Services",
      data: [
        {
          id: 1,
          icon: <Diversity2Icon />,
          title: "Scanners",
          desc: "Wide range of Canon copier products",
          path: "/rental-services-scanners",
          items: [
            {
              title: "Rental Services - Scanners",
              desc: "We offer a wide range of Canon copier products for sale, ensuring that we have the perfect solution...",
              route: "/rental-services-scanners",
            },
            {
              title: "Service & Support",
              desc: "Our team of highly skilled professionals provides professional support for all your copier needs. From product selection to troubleshooting, we are here to assist you every step of the way...",
              route: "/rental-services-scanners",
            },
          ],
        },
        {
          id: 2,
          icon: <ReceiptIcon />,
          title: "Printers",
          path: "/rental-services-scanners",
          items: [
            {
              title: "Rental Services - Printers",
              desc: "We offer a wide range of Canon copier products for sale, ensuring that we have the perfect solution...",
              route: "/rental-services-scanners",
            },
            {
              title: "Service & Support",
              desc: "Our team of highly skilled professionals provides professional support for all your copier needs. From product selection to troubleshooting, we are here to assist you every step of the way...",
              route: "/rental-services-scanners",
            },
          ],
        },
        {
          id: 3,
          icon: <ReceiptIcon />,
          title: "MFP’s",
          path: "/rental-services-scanners",
          items: [
            {
              title: "Rental Services - MFP's",
              desc: "We offer a wide range of Canon copier products for sale, ensuring that we have the perfect solution...",
              route: "/rental-services-scanners",
            },
            {
              title: "Service & Support",
              desc: "Our team of highly skilled professionals provides professional support for all your copier needs. From product selection to troubleshooting, we are here to assist you every step of the way...",
              route: "/rental-services-scanners",
            },
          ],
        },
      ],
    },
    {
      tabName: "Resources",
      data: [
        {
          id: 1,
          icon: <Diversity2Icon />,
          title: "Solutions",
          desc: "Innovative Solutions for Applications Excellence",
          path: "/solutions",
          items: [
            {
              title: "OCR, ICR & OMR Solutions",
              desc: "OCR, ICR, and OMR are all different solutions for extracting data from various types of documents.",
              route: "/solutions",
            }
          ],
        },
       
      ],
    },
  ],
};
export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setSelectedNavItem: (state, action) => {
      state.selectedNavItem = action.payload;
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload.value;
    },
  },
});

export const { setSelectedNavItem, setSelectedIndex } = navbarSlice.actions;
export const navbarState = (state) => state.navbar;
export default navbarSlice.reducer;
