import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
// icons
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import VrModel from "../../../modals/VrModel";

const data = [
  {
    heading: "Providing effective Solutions",
    icon: <EmojiObjectsOutlinedIcon />,
  },
  {
    heading: "Effortless & user-friendly Products",
    icon: <InventoryOutlinedIcon />,
  },
  {
    heading: "Superior services that stimulate Growth",
    icon: <AddTaskOutlinedIcon />,
  },
];
export default function SectionFour() {
  return (
    <Container className="my-5 p-md-5">
      <Grid container spacing={7}>
        <Grid item xs={12} md={5}>
          <Box sx={{ position: "relative", zIndex: 3 }}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                minHeight: "350px",
                maxHeight: "400px",
              }}
            > 
              <VrModel />
            </div> 
            <Box
              sx={{
                position: "absolute",
                top: 20,
                left: 40,
                width:{xs: 250, sm: 350},
                height: 350,
                bgcolor: "#B8B5FF",
                filter: "blur(100px)",
                opacity: 0.9,
                borderRadius: "50%",
                zIndex: -5,
              }}
            ></Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          className="d-flex flex-column align-items-start gap-3"
        >
          <Typography variant="h4" component="h4">
            <strong> Empowering</strong> your growth every step of the way
          </Typography>
          <Typography variant="body1" component="p">
            We are dedicated to supporting your growth at every step, providing
            comprehensive assistance and guidance along the way.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", sm: "start" },
              alignItems: { xs: "center", sm: "start" },
              pr: { md: 5 },
              width: "100%",
            }}
          >
            {data.map(({ heading, icon }, index) => (
              <Box
                key={index}
                className="d-flex flex-column gap-2 text-center w-100"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "calc(100% /3.2)",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "var(--color-yellow) !important",
                    fontSize: "2rem",
                  },
                }}
              >
                {icon}

                <Typography
                  variant="subtitle2"
                  component="p"
                  className="mb-3 mb-md-0"
                >
                  {heading}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
