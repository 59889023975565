import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Col, Row } from "react-bootstrap";

export default function SchoolERP() {
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5" sx={{ textAlign: "justify" }}>
        <Typography variant="h4" component="h5" className="my-4  fw-semibold">
          School Enterprise Resource Planning
        </Typography>
        {/* <img
          src={img1}
          className="img-cus"
          alt=".."
          style={{
            mixBlendMode: "multiply",
            float: "right",
            marginLeft: "10px",
          }}
        /> */}
        <Typography variant="subtitle1" component="p" className="my-2 ">
          <strong>A School ERP </strong>system is a software solution designed
          to streamline and manage various administrative and academic processes
          in educational institutions. It helps automate and integrate different
          functions, such as student information management, attendance
          tracking, exam management, timetable scheduling, fee management,
          communication, and more. Here are some common requirements and
          benefits of a School ERP system:
        </Typography>

        <Typography variant="subtitle1" component="p" className="my-3 ">
          Requirements of a School ERP System:
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Student Information Management:</strong>
          The system should be able to store and manage student data, including
          personal details, academic records, attendance, and discipline
          records.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong> Attendance Tracking: </strong>It should provide a mechanism
          to record and track student attendance, both for daily classes and
          special events.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Exam and Grading Management:</strong> The system should
          facilitate the creation of exam schedules, manage marks/grades, and
          generate report cards.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Timetable Management: </strong>It should allow for the
          creation and management of class timetables, including assigning
          teachers, subjects, and classrooms.
        </Typography>
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Fee Management: </strong>
          The ERP(Enterprise Resource Planning) system should handle fee
          collection, fee reminders, receipt generation, and financial
          reporting.
        </Typography>
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Communication and Collaboration: </strong>
          It should provide communication tools like messaging, notifications,
          and portals for effective interaction between teachers, students, and
          parents.
        </Typography>
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Library Management: </strong>
          The system should include features for cataloging, borrowing, and
          tracking books and other resources in the school library.
        </Typography>
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Staff Management: </strong>
          It should help manage employee records, attendance, leave management,
          payroll, and other HR-related processes.
        </Typography>
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Transportation Management: </strong>
          If the school provides transportation services, the system should
          handle routing, vehicle tracking, and student transportation details.
        </Typography>
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong> Data Security and Privacy: </strong>
          The ERP system should have robust security measures to protect
          sensitive student and staff information.
        </Typography>

        {/* <img
          src={img2}
          className="img-cus"
          alt=".."
          style={{
            mixBlendMode: "multiply",
            float: "left",
            marginRight: "10px",
            marginTop: 10,
          }}
        /> */}
        <Typography
          variant="h4"
          component="p"
          className="mt-5 pt-3 mb-4 text-start"
        >
          Benefits of a School ERP System
        </Typography>
        <Row>
          <Col xs={12} md={6}>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong> Improved Efficiency</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-4">
              Automation of administrative tasks reduces manual effort, leading
              to increased productivity and efficiency.
            </Typography>

            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong> Streamlined Processes </strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-4">
              The system integrates different functions, making it easier to
              manage and access information, leading to streamlined processes.
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Enhanced Communication </strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-4">
              It facilitates seamless communication between teachers, students,
              and parents, improving collaboration and engagement.
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong> Accurate Data Management</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-4">
              The ERP system ensures accurate and up-to-date data, reducing
              errors and enabling better decision-making.
            </Typography>
          </Col>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={5}>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Cost and Time Savings</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-4">
              By automating tasks and reducing paperwork, the system helps save
              time and operational costs.
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Parent Engagement</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-4">
              Parents can access student-related information, such as
              attendance, grades, and homework, through portals, enhancing their
              involvement in their child's education.
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Data Analytics and Reporting</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-4">
              The system provides analytical tools and reports, allowing
              administrators to gain insights into various aspects of the
              school's performance.
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong> Scalability and Customization</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-4">
              A good ERP system can scale with the growing needs of the
              institution and be customized to fit specific requirements.
            </Typography>
          </Col>
        </Row>

        <Typography variant="subtitle2" component="p" className="mb-4">
          These requirements and benefits may vary depending on the specific
          needs and size of the educational institution. It's important for
          schools to assess their unique requirements and choose an ERP system
          that aligns with their goals and objectives.
        </Typography>
      </Container>
    </Box>
  );
}
