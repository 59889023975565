import { Box, Container, Typography } from "@mui/material";
import React from "react";
import AnimateButton from "../container/AnimateButton";
import image from "./Headset.jpg";
export default function App() {
  return (
    <>
      <Box
        // className="bg"
        sx={{
          height: "100vh",
          backgroundImage: `url("https://images.unsplash.com/photo-1644517201382-33da38e945ff?q=80&w=1637&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
          backgroundSize: "cover",
          backgroundPosition: "top left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          component="section"
          sx={{
            position: "relative",
            height: "100%",
            background:
              "radial-gradient(circle, rgba(26,26,26,0.8) 19%, rgba(23,22,22,1) 85%)",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Container
            sx={{

              textAlign: "center",
              width: "100%",
              mb:10
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              className="text-center w-75 mx-auto "
              sx={{ fontWeight: 700, color: "#FFD006" }}
              gutterBottom
            >
              Experience the Extraordinary: Discover Limitless Possibilities in
              VR
            </Typography>
            <Typography
              variant="h6"
              component="subtitle1"
              className="text-center"
              sx={{ color: "#d7d7d7", }}
            >
              Explore, Create, Connect - Your Gateway to Virtual Reality
            </Typography>
            <Box className="d-flex flex-column gap-3 mt-5">
              <AnimateButton
                title="Explore Demos"
                color={
                  "radial-gradient(circle, rgba(255,255,255,0.8935223747702206) 9%, rgba(255,208,6,1) 99%)"
                }
                textcolor={"#0c0c0c"}
                link={"https://virtualtour.nulark.tech/showcase/vr-demos"}
              />
              <Typography
                variant="subtitle2"
                component="p"
                sx={{
                  color: "#5d5d5d",
                }}
              >
                Cost Effective, Ease of Use & Custom Designed Solutions
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
