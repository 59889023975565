import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export default function InstitutionalERP() {
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5" sx={{ textAlign: "justify" }}>
        <Typography variant="h4" component="h5" className="my-4  fw-semibold">
          Institutional Enterprise Resource Planning (ERP) Solution
        </Typography>

        {/* <img
          src={img1}
          className="img-cus"
          alt=".."
          style={{
            mixBlendMode: "multiply",
            float: "right",
            marginLeft: "10px",
          }}
        /> */}
        <Typography variant="subtitle1" component="p" className="my-2 ">
          Implementing an{" "}
          <strong>
            Institutional Enterprise Resource Planning (ERP) solution{" "}
          </strong>{" "}
          in educational institutions offers several benefits and can enhance
          credibility, which in turn can attract more students. Here are some
          key benefits of ERP solutions in educational institutions and how they
          can drive student enrollment:
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong> Streamlined Administrative Processes</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          ERP solutions automate and streamline administrative processes such as
          admissions, registration, course management, fee collection, and
          student records management. This simplifies the administrative
          workload, reduces manual errors, and improves efficiency. Students
          benefit from smoother processes and timely communication, which can
          enhance their overall experience and satisfaction.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Improved Communication and Collaboration</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          ERP systems facilitate seamless communication and collaboration among
          students, faculty, administrators, and parents. Features such as
          online portals, messaging systems, and discussion forums enable
          real-time communication and information sharing. Enhanced
          communication fosters a sense of community and engagement, which can
          attract prospective students looking for an interactive and connected
          learning environment.
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Enhanced Student Services</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          ERP solutions often include modules for student services such as
          academic advising, counseling, career services, and library
          management. By providing easy access to these services and
          personalized support, institutions can better meet the diverse needs
          of students, ensuring a positive learning experience. Word-of-mouth
          recommendations from satisfied students can contribute to the
          credibility and reputation of the institution, attracting more
          prospective students.
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong> Data-driven Decision Making</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          ERP systems provide comprehensive data and analytics capabilities,
          allowing educational institutions to gather insights into various
          aspects of their operations. By analyzing data on student performance,
          enrollment trends, and resource utilization, institutions can make
          data-driven decisions to improve teaching methodologies, resource
          allocation, and student outcomes. Evidence of data-informed
          decision-making can enhance the credibility and reputation of the
          institution, which can be appealing to prospective students.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Integrated Learning Management</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          ERP solutions often integrate with learning management systems (LMS),
          enabling a seamless experience for students and instructors.
          Integrated LMS functionality allows for easy access to course
          materials, online assignments, grading, and student progress tracking.
          Institutions with well-integrated and user-friendly LMS platforms can
          attract tech-savvy students who value a modern and efficient learning
          environment.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong> Efficient Financial Management</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          ERP systems provide robust financial management modules that
          streamline financial operations, including budgeting, expense
          tracking, and financial reporting. Effective financial management
          enhances transparency and accountability, ensuring the proper
          utilization of resources. This financial stability and transparency
          can contribute to the credibility of the institution, attracting
          prospective students and their families who prioritize financial
          responsibility and sustainability.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Scalability and Adaptability</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          ERP solutions are designed to accommodate the needs of educational
          institutions of various sizes and types. Whether it's a small college
          or a large university, ERP systems can scale to handle increased
          student enrollment and adapt to changing educational requirements. The
          ability to efficiently manage growth and adapt to evolving needs can
          contribute to the credibility and reputation of the institution,
          making it an attractive choice for prospective students.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-4">
          In summary, implementing an ERP solution in an educational institution
          can improve administrative processes, enhance communication and
          collaboration, provide better student services, enable data-driven
          decision-making, and offer efficient financial management. These
          benefits, combined with an institution's credibility and reputation,
          can positively influence student enrollment by attracting prospective
          students who value streamlined processes, effective communication,
          personalized support, and a modern learning environment.
        </Typography>
      </Container>
    </Box>
  );
}
