import { Check, CheckCircle, Close, Star } from "@mui/icons-material";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Divider,
} from "@mui/material";
import React from "react";
import {
  basicPlanFeatures,
  basicPlanSuitableFor,
  flexiblePlanFeatures,
  flexiblePlanSuitableFor,
  premiumPlanFeatures,
  premiumPlanSuitableFor,
  ultimatePlanFeatures,
  ultimatePlanSuitableFor,
} from "../utils/const";
import { Controller, useForm } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "90%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function RemoteService() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [result, setResult] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      company: "",
      city: "",
      country: "",
      plan: "Premium Plan",
    },
  });

  const handleEmail = async (data) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      company,
      city,
      country,
      plan,
    } = data;
    var raw = JSON.stringify({
      to: "nularksolutions@gmail.com",
      subject: `Contacted by ${firstName}`,
      text: `Name: ${firstName} ${lastName},
             Email: ${email},
             Phone: ${phoneNumber}
             `,
      html: `
             <table id="customers" style="font-family: Arial, Helvetica, sans-serif;border-collapse: collapse;width: 100%;">
             <tr>
             <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;width:30%">Name</th>
             <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;">Value</th>
           </tr>
             <tr>
               <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Seleted Plan</td>
               <td style="border: 1px solid #ddd;padding: 8px;">${plan}</td>
             </tr>
             <tr>
               <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Name</td>
               <td style="border: 1px solid #ddd;padding: 8px;">${firstName} ${lastName}</td>
             </tr>
             <tr>
               <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Email</td>
               <td style="border: 1px solid #ddd;padding: 8px;">${email}</td>
             </tr>
             <tr>
               <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Mobile Number</td>
               <td style="border: 1px solid #ddd;padding: 8px;">${phoneNumber}</td>
             </tr>
             <tr>
               <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Company</td>
               <td style="border: 1px solid #ddd;padding: 8px;">${company}</td>
             </tr>
             <tr>
               <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">City</td>
               <td style="border: 1px solid #ddd;padding: 8px;">${city}</td>
             </tr>
             <tr>
               <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Country</td>
               <td style="border: 1px solid #ddd;padding: 8px;">${country}</td>
             </tr>
           </table>
           
             `,
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      "https://asia-south1-nulark-blogs.cloudfunctions.net/sendMail",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setResult(true);
        setLoading(false);
        setTimeout(function () {
          setResult(false);
          handleClose();
        }, 3000);
      })
      .catch((error) => console.log("error", error));

    reset();
  };
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className="pt-5">
        <Grid
          container
          spacing={0}
          className="py-4 px-0 mx-0 w-100"
          sx={{
            rowGap: 3,
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className="py-3">
              Remote IT Admin Services
            </Typography>
            <Typography variant="body1">
              Simplify your IT hassle with our Remote Admin Service! Our
              dedicated team keeps your systems running smoothly 24/7, handling
              issues and updates from afar. No more downtime worries – just
              focus on growing your business while we ensure your network stays
              strong and secure. Invest in stress-free IT support today and
              experience the ease of remote management. Boost productivity,
              minimize disruptions – that's the power of our Remote Admin
              Service at your fingertips!
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              className="img-fluid mx-auto d-block"
              style={{ maxWidth: "300px" }}
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/remoteservice/2.svg"
              alt="Remote IT Admin Services"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="py-2">
              Who We are
            </Typography>
            <Typography variant="body1">
              In 2020, we initiated a venture comprising a small team of IT
              management specialists. Our journey began by supporting individual
              clients, ensuring the maintenance of software, sensitive data, and
              content in their IT infrastructure (both local and cloud-based).
              We adeptly implemented cost-effective solutions, often leveraging
              freeware alternatives. Our efforts resulted in substantial monthly
              cost savings for clients, coupled with enhanced data security for
              assets stored in both the cloud and local environments.
              <br /> <br />
              We have four types of remote IT admin service plans you can
              consider:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper
              elevation={1}
              className="p-0"
              sx={{
                overflow: "hidden",
              }}
            >
              <Grid container spacing={0} className="p-0 m-0">
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    bgcolor: "#ffd006",
                  }}
                  className="p-3"
                >
                  <Typography variant="h6" className="py-2" gutterBottom>
                    Basic Plan:
                  </Typography>
                  <Typography variant="subtitle2">Suitable For:</Typography>
                  <List dense={false}>
                    {basicPlanSuitableFor.map((item, index) => (
                      <ListItem key={index} className="px-0">
                        <Typography variant="body1">{item}</Typography>
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant="outlined"
                    color="success"
                    fullWidth
                    onClick={handleOpen}
                  >
                    ENQUIRE NOW
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  className="p-3"
                  sx={{ borderLeft: { xs: "none", md: "1px dashed" } }}
                >
                  <Typography variant="h6" className="py-2" gutterBottom>
                    Essentials Package
                  </Typography>
                  <Typography variant="subtitle2">Feature:</Typography>
                  <List dense={false}>
                    {basicPlanFeatures.map((item, index) => (
                      <ListItem key={index}>
                        <Typography variant="body1">
                          <Check className="text-success me-2" />
                          {item}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={1} className="p-0 ">
              <Grid container spacing={0} className="p-0 m-0">
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ bgcolor: "#ffd006" }}
                  className="p-3"
                >
                  <Typography variant="h6" className="py-2" gutterBottom>
                    Flexible Plan:
                  </Typography>
                  <Typography variant="subtitle2">Suitable For:</Typography>
                  <List dense={false}>
                    {flexiblePlanSuitableFor.map((item, index) => (
                      <ListItem key={index} className="px-0">
                        <Typography variant="body1">{item}</Typography>
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant="outlined"
                    color="success"
                    fullWidth
                    onClick={handleOpen}
                  >
                    ENQUIRE NOW
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  className="p-3"
                  sx={{ borderLeft: { xs: "none", md: "1px dashed" } }}
                >
                  <Typography variant="h6" className="py-2" gutterBottom>
                    Adaptable Package
                  </Typography>
                  <Typography variant="subtitle2">
                    Additional Features:
                  </Typography>
                  <List dense={false}>
                    {flexiblePlanFeatures.map((item, index) => (
                      <ListItem key={index}>
                        <Typography variant="body1">
                          <Check className="text-success me-2" />
                          {item}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={1} className="p-0 ">
              <Typography
                variant="body2"
                className="text-center text-white rounded-top"
                bgcolor={"#1976d2"}
              >
                Recommend Plan
              </Typography>
              <Grid container spacing={0} className="p-0 m-0">
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ bgcolor: "#ffd006" }}
                  className="p-3"
                >
                  <Typography variant="h6" className="py-2" gutterBottom>
                    Premium Plan:
                  </Typography>
                  <Typography variant="subtitle2">Suitable For:</Typography>
                  <List dense={false}>
                    {premiumPlanSuitableFor.map((item, index) => (
                      <ListItem key={index} className="px-0">
                        <Typography variant="body1">{item}</Typography>
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant="outlined"
                    color="success"
                    fullWidth
                    onClick={handleOpen}
                  >
                    ENQUIRE NOW
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  className="p-3"
                  sx={{ borderLeft: { xs: "none", md: "1px dashed" } }}
                >
                  <Typography variant="h6" className="py-2" gutterBottom>
                    Comprehensive Package
                  </Typography>
                  <Typography variant="subtitle2">
                    Additional Features:
                  </Typography>
                  <List dense={false}>
                    {premiumPlanFeatures.map((item, index) => (
                      <ListItem key={index} className="px-0">
                        <Typography variant="body1">
                          <Check className="text-success me-2" />
                          {item}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={1} className="p-0 ">
              <Grid container spacing={0} className="p-0 m-0">
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ bgcolor: "#ffd006" }}
                  className="p-3"
                >
                  <Typography variant="h6" className="py-2" gutterBottom>
                    Ultimate Plan:
                  </Typography>
                  <Typography variant="subtitle2">Suitable For:</Typography>
                  <List dense={false}>
                    {ultimatePlanSuitableFor.map((item, index) => (
                      <ListItem key={index} className="px-0">
                        <Typography variant="body1">{item}</Typography>
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant="outlined"
                    color="success"
                    fullWidth
                    onClick={handleOpen}
                  >
                    ENQUIRE NOW
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  className="p-3"
                  sx={{ borderLeft: { xs: "none", md: "1px dashed" } }}
                >
                  <Typography variant="h6" className="py-2" gutterBottom>
                    Enterprise Package
                  </Typography>
                  <Typography variant="subtitle2">
                    Additional Features:
                  </Typography>
                  <List dense={false}>
                    {ultimatePlanFeatures.map((item, index) => (
                      <ListItem key={index}>
                        <Typography variant="body1">
                          <Check className="text-success me-2" />
                          {item}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="py-2" gutterBottom>
              Freebies included:
            </Typography>
            <Box component={"ul"}>
              <Typography component={"li"} variant="body1">
                Free assessment of the existing IT setup
              </Typography>
              <Typography component={"li"} variant="body1">
                Ticket-based or plan-based, personalized, affordable quote
              </Typography>
              <Typography component={"li"} variant="body1">
                Free file management system (cloud/local implementation with
                minimal hosting expenses)
              </Typography>
              <Typography component={"li"} variant="body1">
                Opportunity to obtain Free blog manager; dynamic website
                according to your preferences
              </Typography>
              <Typography component={"li"} variant="body1">
                One or more free IT health checkup per year
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" className="py-2" gutterBottom>
              Notes:
            </Typography>
            <Box component={"ul"}>
              <Typography component={"li"} variant="body1">
                Each plan builds upon the features of the previous one,
                providing a clear progression of value.
              </Typography>
              <Typography component={"li"} variant="body1">
                Clearly we will outline the limitations and scope of each plan
                to manage client expectations.
              </Typography>
              <Typography component={"li"} variant="body1">
                We will consider including discounts for annual subscriptions or
                bundling additional services.
              </Typography>
              <Typography component={"li"} variant="body1">
                We will communicate the value of each plan to clients,
                emphasizing how it addresses their unique needs.
              </Typography>
              <Typography component={"li"} variant="body1">
                Customized costs will be considered based on the number of
                employees, software tools local, cloud and servers.
              </Typography>
              <Typography component={"li"} variant="body1">
                Physical hardware repair or networking issues or repairs - we
                can appoint a local partner or we will coordinate with your
                local partner based on our agreement.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/remoteservice/1.svg"
              alt="freebies"
              className="img-fluid mx-auto d-block"
              style={{ maxWidth: "300px" }}
            />
          </Grid>
        </Grid>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="d-flex justify-content-between align-items-center mb-2">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Enquire Now
            </Typography>
            <IconButton variant="outlined" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Grid
            container
            spacing={3}
            className="py-4"
            component={"form"}
            onSubmit={handleSubmit(handleEmail)}
          >
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                variant="standard"
                color="info"
                placeholder="First Name"
                fullWidth
                required
                {...register("firstName")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                variant="standard"
                color="info"
                placeholder="Last Name"
                fullWidth
                required
                {...register("lastName")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                variant="standard"
                color="info"
                placeholder="Email"
                fullWidth
                required
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                variant="standard"
                color="info"
                placeholder="Phone (+91 99736890)"
                fullWidth
                type="tel"
                {...register("phoneNumber")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                variant="standard"
                color="info"
                placeholder="Company"
                fullWidth
                required
                {...register("company")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                variant="standard"
                color="info"
                placeholder="City"
                fullWidth
                required
                {...register("city")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                variant="standard"
                color="info"
                placeholder="Country"
                fullWidth
                required
                {...register("country")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                color="info"
                size="small"
                fullWidth
              >
                <Controller
                  name="plan"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                    >
                      <MenuItem value={"Basic Plan"}> Basic Plan</MenuItem>
                      <MenuItem value={"Flexible Plan"}>Flexible Plan</MenuItem>
                      <MenuItem value={"Premium Plan"}>Premium Plan</MenuItem>
                      <MenuItem value={"Ultimate Plan"}>Ultimate Plan</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={loading}
                variant="contained"
                type="submit"
                color="success"
                fullWidth
              >
                Submit
              </Button>
              <Box
                sx={{
                  display: result ? "flex" : "none",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                <CheckCircle sx={{ color: "#337e36", mr: 1 }} />
                <Typography level="h5" color="success">
                  Submitted successfully
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}
