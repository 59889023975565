import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export default function CustomWebApplication() {
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5" sx={{ textAlign: "justify" }}>
        <Typography variant="h4" component="h5" className="my-4  fw-semibold">
          Custom Web Applications
        </Typography>
        <img
          src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/gif2.gif"
          className="img-cus"
          alt=".."
          style={{
            mixBlendMode: "multiply",
            float: "right",
            marginLeft: "10px",
          }}
        />
        <Typography variant="subtitle1" component="p" className="my-2 ">
          <strong>Custom web applications</strong> are tailor-made software
          solutions developed specifically for a business or organization to
          address their unique requirements and processes. Here are some
          benefits of custom web applications and the types of entities that
          would benefit from them:
        </Typography>

        <Typography variant="subtitle1" component="p" className="my-3 ">
          Benefits of Custom Web Applications:
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Tailored to Specific Needs:</strong> Custom web applications
          are designed to meet the specific needs and workflows of a business.
          They are built to align with the organization's processes, objectives,
          and industry requirements, providing a more efficient and effective
          solution compared to off-the-shelf software.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Increased Efficiency and Productivity:</strong> Custom web
          applications streamline business processes by automating tasks,
          eliminating manual work, and providing integrated features. They can
          improve efficiency, reduce errors, and save time, resulting in
          increased productivity for the organization.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Scalability and Flexibility:</strong>
          Custom web applications can be developed to accommodate future growth
          and scalability. They can easily adapt to changing business
          requirements and can be modified or expanded as the organization
          evolves. This scalability and flexibility ensure that the application
          remains relevant and valuable in the long term.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Enhanced User Experience: </strong>
          With a custom web application, the user experience can be optimized
          based on the specific needs of the organization and its users. The
          application can have a user-friendly interface, intuitive navigation,
          and personalized features that align with the organization's branding
          and user preferences.
        </Typography>
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Integration Capabilities:</strong>
          Custom web applications can be integrated with existing systems,
          databases, or third-party applications used by the organization. This
          integration enables seamless data exchange, improved collaboration,
          and consolidated information management.
        </Typography>
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Data Security and Privacy:</strong>
          Custom web applications offer enhanced data security measures,
          ensuring that sensitive business information is protected. The
          application can be built with robust security features, access
          controls, and encryption techniques to safeguard data and comply with
          privacy regulations.
        </Typography>
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Competitive Advantage: </strong>
          Custom web applications give businesses a competitive edge by
          providing unique functionalities, tailored experiences for customers
          or users, and efficient workflows. They can help differentiate an
          organization from its competitors and provide a more personalized and
          optimized solution.
        </Typography>

        <img
          src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/gif3.gif"
          className="img-cus"
          alt=".."
          style={{
            mixBlendMode: "multiply",
            float: "left",
            marginRight: "10px",
          }}
        />
        <Typography variant="h4" component="p" className="mt-4 mb-4 text-start">
          Who Would Need Custom Web Applications?
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong> Businesses with Unique Processes:</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Organizations with specific workflows, industry-specific requirements,
          or complex operations can benefit from custom web applications that
          cater to their specific needs.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong> Startups and Growing Businesses: </strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Startups or small businesses aiming to scale and expand can leverage
          custom web applications to streamline their processes, increase
          efficiency, and provide a competitive advantage in the market.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Enterprises with Legacy Systems: </strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Established enterprises that have legacy systems or outdated software
          can opt for custom web applications to modernize their operations,
          improve integration, and overcome the limitations of off-the-shelf
          software.
        </Typography>
        <br />
        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Service-based Industries:</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Industries such as healthcare, finance, logistics, education, and
          professional services often require custom web applications to manage
          complex data, streamline operations, ensure compliance, and enhance
          customer experiences.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Organizations with Unique Customer Needs: </strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-4">
          Businesses that require personalized user experiences, such as
          e-commerce platforms, online marketplaces, or membership-based
          organizations, can benefit from custom web applications that provide
          tailored features and functionalities.
        </Typography>

        <Typography variant="subtitle2" component="p" className="mb-4">
          In summary, custom web applications offer tailored solutions to
          address specific business needs, enhance efficiency, improve user
          experiences, and provide a competitive advantage. They are
          particularly valuable for businesses or organizations with unique
          processes, scalability requirements, integration needs, and a focus on
          personalized experiences for their customers or users.
        </Typography>
      </Container>
    </Box>
  );
}
