import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay, Navigation } from "swiper";
import { Typography } from "@mui/material";

export default function Slides(props) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper h-100"
        style={{}}
      >
        {props.data.map((item, i) => (
          <SwiperSlide key={i} className="d-flex flex-column h-100">
            <img
              src={item.src}
              className="img-fluid m-auto"
              alt="slides"
              style={{
                m: "auto",
                objectFit: "contain",
              }}
            />
            {item.name ? (
              <Typography variant="h6" component="h6">
                {item.name}
              </Typography>
            ) : null}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
