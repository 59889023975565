import React from "react";
import { Box, Container, Tab, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Col, Row } from "react-bootstrap";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function Solutions() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5" sx={{ textAlign: "justify" }}>
        <Typography variant="h4" component="h5" className="mt-3">
          OCR, ICR & OMR Solutions
        </Typography>
        <Box>
          <Typography variant="subtitle1" component="p" className="mt-3">
            OCR, ICR, and OMR are all different solutions for extracting data
            from various types of documents. Here's an explanation of each:
          </Typography>
          <Typography variant="subtitle1" component="li" className="mb-2 mt-4">
            <strong> OCR (Optical Character Recognition): </strong> OCR is a
            technology that enables the recognition and extraction of printed or
            handwritten text from scanned documents, images, or other media. It
            converts the text into machine-readable characters, allowing for
            further processing and analysis. OCR is commonly used to digitize
            printed documents such as books, invoices, forms, or any text-based
            material. It can also be used for recognizing and extracting text
            from images captured by cameras or mobile devices.
          </Typography>
          <Typography variant="subtitle1" component="li" className="mb-2 mt-4">
            <strong> ICR (Intelligent Character Recognition): </strong> ICR is
            an advanced form of OCR that specializes in recognizing and
            extracting handwritten characters. While OCR primarily focuses on
            printed text, ICR can interpret and convert handwritten text into
            machine-readable characters. ICR systems use sophisticated
            algorithms and machine learning techniques to recognize different
            handwriting styles and variations. It is commonly used in
            applications where capturing and digitizing handwritten forms,
            surveys, or other handwritten documents is required.
          </Typography>
          <Typography variant="subtitle1" component="li" className="mb-2 mt-4">
            <strong> OMR (Optical Mark Recognition): </strong>OMR is a
            technology used to detect and extract data from pre-defined marked
            areas, typically in the form of checkboxes, bubbles, or filled-in
            circles on documents. OMR systems are designed to identify the
            presence or absence of specific marks and interpret them as data.
            This technology is commonly used in applications such as surveys,
            tests, ballot papers, or any document where responses are indicated
            by marking predefined areas.
          </Typography>
          <Typography variant="subtitle1" component="p" className="mt-3">
            OCR, ICR, and OMR technologies can be used individually or in
            combination, depending on the requirements of a specific document
            processing task. These solutions have applications in various
            industries, including finance, healthcare, education, government,
            and more, where efficient data extraction and digitization are
            necessary.
          </Typography>
        </Box>
        <Typography variant="h5" component="p" className="my-5">
          More detailed informations
        </Typography>
        <Box className="d-flex justify-content-center  flex-column ">
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "#ffd10611",
                borderRadius: 2,
                boxShadow: "0px 5px 10px #EEEDFF",
                width: "100%",
                "& .MuiTab-root": {
                  fontWeight: "bold",
                  color: "#0c0c0c",
                  wordWrap: "break-word",
                  fontSize: "1rem",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#0c0c0c",
                },
              }}
            >
              <TabList onChange={handleChange} aria-label="solutions">
                <Tab label=" OCR" value="1" />
                <Tab label="ICR" value="2" />
                <Tab label="OMR" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1" className="px-1 px-md-3">
              <Box className=" pt-3 ">
                <Typography variant="h6" component="h5" className="mt-3">
                  OCR (Optical Character Recognition)
                </Typography>
                <Typography variant="subtitle1" component="p" className="mt-3">
                  OCR (Optical Character Recognition) is a technology that
                  recognizes and extracts text from scanned documents, images,
                  or other media. Here's how OCR can be implemented, its
                  benefits, and some common use cases:
                </Typography>
                <Typography variant="subtitle1" component="p" className="mt-4">
                  Implementation of OCR:
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-3"
                >
                  <strong> Document Scanning: </strong> OCR can be implemented
                  in document scanning devices or software applications to
                  capture images of printed text.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-4"
                >
                  <strong> Image Pre-processing: </strong> Prior to OCR
                  recognition, the scanned images may undergo pre-processing
                  steps such as noise reduction, image enhancement, and
                  deskewing to improve the quality and readability of the text.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-4"
                >
                  <strong> Text Recognition : </strong> OCR algorithms analyze
                  the scanned images and identify patterns to recognize
                  individual characters and words. These algorithms utilize
                  machine learning and pattern recognition techniques to improve
                  accuracy.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-4"
                >
                  <strong> Text Extraction :</strong> Once the text is
                  recognized, the OCR system converts it into machine-readable
                  format, such as editable text or searchable text within a
                  document.
                </Typography>
                <Row className="mx-0">
                  <Col xs={12} md={6} className="order-md-2 d-flex">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/OCR.jpg"
                      alt=".."
                      className="img-fluid"
                      style={{
                        mixBlendMode: "multiply",
                        m: "auto",
                        objectFit: "cover",
                        width: "60%",
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Box className="d-flex flex-column align-items-start gap-1 mt-4">
                      <Typography variant="h5" component="h6" className="my-4">
                        Benefits of OCR:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong>Automation and Efficiency </strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OCR eliminates the need for manual data entry by
                        automating the extraction of text from documents. This
                        reduces time and labor associated with manual
                        transcription.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Improved Accuracy</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OCR systems employ advanced recognition algorithms,
                        resulting in high accuracy rates for text extraction.
                        They can handle different fonts, sizes, and languages.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Time and Cost Savings</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        By streamlining data capture processes, OCR reduces
                        operational costs and increases productivity. It
                        accelerates document processing, retrieval, and search
                        capabilities.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Data Integration</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OCR enables the integration of text data into databases,
                        content management systems, or other applications,
                        making it easily accessible and searchable.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Preservation of Information</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OCR can be used to digitize and preserve printed
                        materials, such as books, historical documents, and
                        archival records, ensuring their longevity and
                        accessibility.
                      </Typography>
                    </Box>
                  </Col>
                </Row>
                <Row className="mx-0">
                  <Col xs={12} md={6} className="d-flex">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/OCR.gif"
                      alt=".."
                      style={{
                        mixBlendMode: "multiply",
                        my: "auto",
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Box className="d-flex flex-column align-items-start gap-1 mt-4">
                      <Typography variant="h5" component="h6" className="my-4">
                        Use Cases of OCR:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong>Document Digitization </strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OCR is widely used to convert printed documents, such as
                        invoices, contracts, forms, and reports, into digital
                        formats for storage, retrieval, and analysis.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Data Entry and Extraction</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OCR automates data entry from documents like receipts,
                        business cards, or identity documents, extracting
                        relevant information such as names, addresses, or
                        transaction details
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Document Search and Retrieval</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OCR makes documents searchable, allowing users to
                        quickly locate specific information within large volumes
                        of text-based documents.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Content Analysis and Insights</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OCR combined with natural language processing (NLP)
                        enables text analysis, sentiment analysis, and
                        information extraction for applications like market
                        research, sentiment analysis, or data mining.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Accessibility</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OCR assists individuals with visual impairments by
                        converting printed materials into accessible formats,
                        such as screen-readable text or audio.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Translation Services</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OCR can be used to extract text from documents in one
                        language and facilitate automatic translation into
                        another language.
                      </Typography>
                    </Box>
                  </Col>
                </Row>
                <Typography variant="subtitle1" component="p" className="mb-4">
                  These are just a few examples of how OCR can be implemented
                  and the benefits it provides. OCR technology has a broad range
                  of applications across industries such as finance, healthcare,
                  legal, education, and more, where efficient text extraction
                  and digitization are essential.
                </Typography>
              </Box>
            </TabPanel>
            <TabPanel value="2" className="px-1 px-md-3">
              <Box className=" pt-3">
                <Typography variant="h6" component="h5" className="mt-3">
                  ICR (Intelligent Character Recognition)
                </Typography>
                <Typography variant="subtitle1" component="p" className="mt-3">
                  ICR (Intelligent Character Recognition) is a technology that
                  enables the recognition and extraction of handwritten
                  characters from documents. Here's how ICR can be implemented,
                  its benefits, and some common use cases:
                </Typography>
                <Typography variant="subtitle1" component="p" className="mt-4">
                  Implementation of ICR:
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-3"
                >
                  <strong> Data Capture: </strong> ICR systems are integrated
                  into document capture software or devices such as scanners,
                  mobile apps, or digital pens. These systems use image
                  processing algorithms and machine learning techniques to
                  analyze and recognize handwritten characters.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-4"
                >
                  <strong> Pre-processing: </strong> Prior to recognition, the
                  captured images may undergo pre-processing steps such as noise
                  reduction, image enhancement, and normalization to improve the
                  accuracy of character recognition.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-4"
                >
                  <strong> Handwriting Recognition : </strong> ICR algorithms
                  analyze the captured images and apply pattern recognition
                  methods to identify and interpret handwritten characters.
                  These algorithms can handle different handwriting styles,
                  variations, and languages.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-4"
                >
                  <strong> Data Extraction:</strong> Once the characters are
                  recognized, the ICR system converts them into machine-readable
                  text, allowing for further processing, data extraction, or
                  integration into databases or other systems.
                </Typography>
                <Row className="mx-0">
                  <Col xs={12} md={6} className="order-md-2 d-flex">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/ICR.jpeg"
                      alt=".."
                      className="img-fluid"
                      style={{
                        mixBlendMode: "multiply",
                        m: "auto",
                        objectFit: "cover",
                        width: "60%",
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Box className="d-flex flex-column align-items-start gap-1 mt-4">
                      <Typography variant="h5" component="h6" className="my-4">
                        Benefits of ICR:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong>Improved Efficiency </strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        ICR automates the extraction of handwritten data,
                        eliminating the need for manual data entry. This saves
                        time and reduces errors associated with manual
                        transcription.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong>Enhanced Accuracy</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        ICR systems utilize advanced recognition algorithms and
                        machine learning techniques, resulting in high accuracy
                        rates for recognizing handwritten characters.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Handwriting Flexibility</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        ICR is designed to recognize various handwriting styles,
                        including cursive, print, and mixed handwriting, making
                        it adaptable to different types of documents and users.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Cost Reduction</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        By automating data capture and reducing manual labor,
                        ICR helps organizations reduce costs associated with
                        data entry and document processing.
                      </Typography>
                    </Box>
                  </Col>
                </Row>
                <Box className="d-flex flex-column align-items-start gap-1 mt-4">
                  <Typography variant="h5" component="h6" className="my-4">
                    Use Cases of ICR:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Forms Processing </strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    ICR can be used to extract data from handwritten forms, such
                    as surveys, application forms, or patient records. It
                    eliminates the need for manual data entry, improving
                    efficiency and accuracy.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Handwritten Notes Digitization</strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    ICR enables the conversion of handwritten notes or documents
                    into machine-readable text, making it easier to search,
                    store, and analyze the information digitally.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Banking and Finance</strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    ICR is used in banking and finance to process handwritten
                    checks, forms, and financial documents. It speeds up data
                    extraction, reduces errors, and improves overall operational
                    efficiency.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Healthcare</strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    ICR finds applications in healthcare for digitizing
                    handwritten medical records, prescriptions, and patient
                    forms. It simplifies data capture and enables seamless
                    integration with electronic health record systems.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Postal Services</strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    ICR can be utilized by postal services for automated address
                    recognition on envelopes and parcels, facilitating efficient
                    mail sorting and delivery.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Education</strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    ICR helps in grading handwritten exams, capturing student
                    responses on answer sheets, and digitizing hand-written
                    educational materials.
                  </Typography>
                </Box>
                <Typography variant="subtitle1" component="p" className="mb-4">
                  These are just a few examples of how ICR can be implemented
                  and the benefits it offers. The technology continues to
                  evolve, and its applications are expanding across various
                  industries to streamline data capture processes and enhance
                  efficiency.
                </Typography>
              </Box>
            </TabPanel>
            <TabPanel value="3" className="px-1 px-md-3">
              <Box className="pt-3 ">
                <Typography variant="h6" component="h5" className="mt-3">
                  OMR (Optical Mark Recognition)
                </Typography>
                <Typography variant="subtitle1" component="p" className="mt-3">
                  OMR (Optical Mark Recognition) is a technology used to detect
                  and extract data from predefined marked areas, such as
                  checkboxes, bubbles, or filled-in circles, on documents.
                  Here's how OMR can be implemented, its benefits, and some
                  common use cases:
                </Typography>
                <Typography variant="subtitle1" component="p" className="mt-4">
                  Implementation of OMR:
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-3"
                >
                  <strong> Document Design: </strong> OMR requires the design of
                  specific forms or documents with predetermined areas where
                  respondents can mark their answers or choices using
                  checkboxes, bubbles, or similar indicators.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-4"
                >
                  <strong> Scanning: </strong> OMR scanners or devices capture
                  the images of the marked documents. These scanners use
                  specialized sensors to detect the presence or absence of marks
                  and capture data accordingly.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-4"
                >
                  <strong> Mark Detection : </strong> OMR software processes the
                  scanned images and applies algorithms to detect and interpret
                  the marked areas. It identifies the positions and patterns of
                  the marks on the document.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="li"
                  className="mb-2 mt-4"
                >
                  <strong> Data Extraction:</strong> Once the marks are
                  detected, the OMR system extracts the corresponding data, such
                  as selected choices, responses, or scores, from the marked
                  areas.
                </Typography>
                <Row className="mx-0">
                  <Col xs={12} md={6} className="order-md-2 d-flex">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/OMR.jpg"
                      alt=".."
                      className="img-fluid"
                      style={{
                        mixBlendMode: "multiply",
                        m: "auto",
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Box className="d-flex flex-column align-items-start gap-1 mt-4">
                      <Typography variant="h5" component="h6" className="my-4">
                        Benefits of OMR:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Speed and Efficiency </strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OMR enables the quick and automated extraction of data
                        from multiple-choice or checkbox-based documents,
                        significantly reducing the time and effort required for
                        manual data entry.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong>Accuracy</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OMR systems provide high accuracy in recognizing and
                        extracting marked data. They are designed to handle
                        various mark formats, sizes, and variations.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Cost Savings</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        By automating the data capture process, OMR reduces the
                        need for manual data entry, leading to cost savings and
                        increased productivity.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Scalability</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OMR can process large volumes of documents and capture
                        data from multiple respondents simultaneously, making it
                        suitable for applications that involve surveys, exams,
                        or ballot processing.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-2"
                      >
                        <TaskAltIcon
                          className="me-1 mb-1"
                          sx={{ color: "#00A884" }}
                        />
                        <strong> Error Reduction</strong>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className="mb-4"
                      >
                        OMR eliminates errors associated with manual data entry,
                        ensuring data accuracy and integrity.
                      </Typography>
                    </Box>
                  </Col>
                </Row>

                <Box className="d-flex flex-column align-items-start gap-1 mt-4">
                  <Typography variant="h5" component="h6" className="my-4">
                    Use Cases of OMR:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Surveys and Questionnaires </strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    OMR is commonly used to process surveys and questionnaires
                    where respondents mark their answers. It simplifies data
                    collection, tabulation, and analysis.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Examinations and Tests</strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    OMR facilitates the automated grading and analysis of
                    multiple-choice exams, eliminating the need for manual
                    marking and reducing grading time.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Election and Voting Systems</strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    OMR plays a crucial role in ballot processing for elections.
                    It enables the rapid counting and tabulation of marked
                    ballots, ensuring accurate results.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Attendance Tracking</strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    OMR can be used to record attendance by having individuals
                    mark their presence on attendance sheets or cards. This
                    streamlines attendance tracking and reduces manual effort.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Feedback Forms</strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    OMR is utilized in feedback collection processes, where
                    respondents provide ratings or feedback by marking
                    predefined options on forms.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-2"
                  >
                    <TaskAltIcon
                      className="me-1 mb-1"
                      sx={{ color: "#00A884" }}
                    />
                    <strong> Data Collection in Research</strong>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mb-4"
                  >
                    OMR simplifies data collection in research studies, allowing
                    participants to mark their responses on survey forms,
                    questionnaires, or data collection sheets.
                  </Typography>
                </Box>

                <Typography variant="subtitle1" component="p" className="mb-4">
                  These are just a few examples of how OMR can be implemented
                  and the benefits it offers. OMR technology finds applications
                  in education, market research, elections, data collection, and
                  other domains where efficient and accurate data extraction
                  from marked documents is required.
                </Typography>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Box>
  );
}
