import React from "react";
import { Box, Typography } from "@mui/material";
export default function Footer() {
  return (
    <Box
      className="d-flex"
      sx={{
        background: "#eee",
        py: 2,
      }}
    >
      <Typography variant="subtitle2" component="p">
        Copyright @2023 NuLark Solutions | All Rights Reserved
      </Typography>
    </Box>
  );
}
