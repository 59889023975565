import React from "react";
import Header from "../Components/Header/App";
import Main from "../Components/Main/App";
import SectionOne from "../Components/Main/Sections/SectionOne";
import SectionFour from "../Components/Main/Sections/SectionFour";
import SectionThree from "../Components/Main/Sections/SectionThree";
import Pricing from "../Components/Pricing/Pricing"
import LogoCollection from "../Components/LogoCollection";
export default function Homepage() {
  return (
    <>
      <Header /> 
      <Pricing />
      <SectionThree />
      {/* <SectionOne /> */}
      <LogoCollection/>
      <Main />
      <SectionFour />
    </>
  );
}
