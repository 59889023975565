import React from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "../Pages/Homepage";
import Contactpage from "../Components/Contact/Contactpage";
import Aboutpage from "../Pages/Aboutpage";
import VirtualTourpage from "../Pages/ItServices/VirtualTourpage";
import CustomDynamicWebsite from "../Pages/ItServices/CustomDynamicWebsite";
import CustomWebApplication from "../Pages/ItServices/CustomWebApplication";
import CustomMobileApp from "../Pages/ItServices/CustomMobileApp";
import SchoolERP from "../Pages/ItServices/SchoolERP";
import College from "../Pages/ItServices/College";
import InstitutionalERP from "../Pages/ItServices/InstitutionalERP";
import WebsiteBuider from "../Pages/Products/WebsiteBuider";
import RMS from "../Pages/Products/RMS";
import PMS from "../Pages/Products/PMS";
import Scanners from "../Pages/Rentals/Scanners";
import Solutions from "../Pages/Solutions";
import TrainingPage from "../Pages/TrainingPage";
import RemoteService from "../Pages/RemoteServices";
export default function Root() {
  return (
    <>
      <Routes>
        <Route>
          <Route path="/" element={<Homepage />} />
          <Route path="/contact" element={<Contactpage />} />
          <Route path="/about" element={<Aboutpage />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/onlinetraining" element={<TrainingPage />} />
          <Route path="/remoteservice" element={<RemoteService />} />

          {/* IT Services */}
          <Route path="/virtual-tour" element={<VirtualTourpage />} />
          <Route
            path="/custom-dynamic-webiste"
            element={<CustomDynamicWebsite />}
          />
          <Route
            path="/custom-web-application"
            element={<CustomWebApplication />}
          />
          <Route path="/custom-mobile-app" element={<CustomMobileApp />} />
          <Route path="/school-erp" element={<SchoolERP />} />
          <Route
            path="/college-university-evaluation-system"
            element={<College />}
          />
          <Route path="/institutional-erp" element={<InstitutionalERP />} />

          {/* Products */}
          <Route path="/website-builder" element={<WebsiteBuider />} />
          <Route path="/registration-management-system" element={<RMS />} />
          <Route path="/pms" element={<PMS />} />

          {/* Rentals */}
          <Route path="/rental-services-scanners" element={<Scanners />} />
        </Route>
      </Routes>
    </>
  );
}
