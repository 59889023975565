import { Box, Container, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Slides from "../../Components/container/Slides";
const dataTop = [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/icc-bucket.appspot.com/o/slide3.png?alt=media&token=267384cb-5ece-41af-9055-54c6f0621ec5",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/icc-bucket.appspot.com/o/slide4.png?alt=media&token=4a03c2ec-f68b-499f-961e-b3d1ee3386c6",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/icc-bucket.appspot.com/o/slide5.png?alt=media&token=48f55e5b-0bbe-4f49-95a2-a30477a36dc1",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/icc-bucket.appspot.com/o/slide6.png?alt=media&token=314880b5-8922-4d58-81a4-8b022397effe",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/icc-bucket.appspot.com/o/slide7.png?alt=media&token=7c69cf62-c1c1-4c95-814b-51a812e8b270",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/icc-bucket.appspot.com/o/slide8.png?alt=media&token=883e1fef-ff3f-4838-8c83-8c5a7c22252e",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/icc-bucket.appspot.com/o/slide2.png?alt=media&token=391f6b0b-6f5c-4cb5-b6db-bbe99ec88768",
  },
];

export default function Scanners() {
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5">
        <Typography variant="h4" component="h5" className="my-3">
          Rental Services - Scanners, Printers, MFP's
        </Typography>
        <Typography variant="subtitle1" component="p" className="my-3">
          We offer a wide range of Canon copier products for sale, ensuring that
          we have the perfect solution for your business needs.
        </Typography>

        <Row>
          <Col xs={12} md={6} className="order-md-2">
            <Slides data={dataTop} />
          </Col>
          <Col xs={12} md={6}>
            <Typography variant="subtitle1" component="li" className="my-2 ">
              <strong>Black and White Copiers: </strong> Our inventory includes
              a variety of black and white copiers from Canon. These copiers are
              ideal for businesses that primarily require standard document
              printing and copying without the need for color output. Black and
              white copiers are known for their high-speed performance,
              excellent image quality, and cost-effectiveness.
            </Typography>

            <Typography variant="subtitle1" component="li" className="my-2 ">
              <strong> Extensive Selection: </strong> We take pride in offering
              a diverse range of top-of-the-line business copiers. Whether you
              need a high-speed multifunction copier, a color laser copier, or a
              specialized copier for large-scale printing needs, we have the
              right equipment to meet your requirements.
            </Typography>

            <Typography variant="subtitle1" component="li" className="my-2 ">
              <strong>Flexible Rental Options: </strong> We understand that
              every business has unique needs, which is why we provide flexible
              rental options. You can rent a copier for a month, week, or even a
              single day, allowing you to choose the rental period that suits
              your project or business demands.
            </Typography>

            <Typography variant="subtitle1" component="li" className="my-2 ">
              <strong>Upgrade and Change Rental Program: </strong> We believe in
              offering maximum flexibility to our customers. If you find that
              your copier requirements evolve during the rental period, you can
              easily upgrade to a more advanced model or switch to a different
              rental program that better aligns with your needs. We strive to
              ensure that you always have the right equipment to enhance your
              productivity.
            </Typography>
          </Col>
        </Row>
        {/* <Row>
          <Col xs={12} md={6}>
            <Slides data={data} />
          </Col>
          <Col xs={12} md={6}> */}
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Month-to-Month Contracts: </strong>
          Our month-to-month rental contracts provide excellent value for
          businesses of all sizes. With no long-term commitments or obligations,
          you have the freedom to adjust your copier rental as your business
          needs change.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Upgrade and Change Rental Program: </strong> We believe in
          offering maximum flexibility to our customers. If you find that your
          copier requirements evolve during the rental period, you can easily
          upgrade to a more advanced model or switch to a different rental
          program that better aligns with your needs. We strive to ensure that
          you always have the right equipment to enhance your productivity.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Parts, Toner, and Supplies: </strong> We provide all necessary
          parts, toner cartridges, and supplies as part of the copier rental
          package. You won't have to worry about sourcing these items separately
          or incurring additional costs. Our goal is to make the copier rental
          experience hassle-free for our customers.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Reliable Performance: </strong> Our business copiers are known
          for their reliability and consistent performance. By renting from us,
          you can benefit from the latest technology and ensure that your
          printing and copying tasks are completed efficiently and without
          interruptions.
        </Typography>
        {/* </Col>
        </Row> */}
        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Expert Support: </strong>
          Our team of professionals is dedicated to providing exceptional
          customer support throughout your copier rental period. Whether you
          have questions, need assistance, or require technical guidance, we are
          here to help. We value your satisfaction and aim to exceed your
          expectations.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Cost-Effective Solution: </strong> Renting a copier from us
          offers a cost-effective solution compared to purchasing a new copier
          outright. It allows you to access high-quality equipment without a
          significant upfront investment, making it an ideal option for
          businesses looking to optimize their budget while maintaining
          productivity.
        </Typography>

        <Typography
          variant="h4"
          component="p"
          className="mt-5 pt-3 mb-4 text-start"
        >
          Service & Support
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Scheduled Maintenance Services</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Accurate Problem Diagnosis</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Prompt repair</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Yearly Maintenance Contract</strong>
        </Typography>
        <Typography variant="subtitle2" component="p" className="mb-2">
          <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
          <strong>Full service</strong>
        </Typography>
      </Container>
    </Box>
  );
}
