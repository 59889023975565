import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export default function PMS() {
  return (
    <Box className="bg-dots py-5">
      <Container maxWidth="lg" className=" pt-5">
        <Typography variant="h4" component="h5" className="mt-3">
          Project Management System (PMS)
        </Typography>
        <Typography variant="body1" component="p" className="mt-3">
          The well-designed project and task management system NuLark PMS
          enables your customers to create and manage projects and tasks.
          <br />
          PMS, or Project Management System, is a crucial tool used in various
          industries, including engineering and construction, to effectively
          plan, organize, and execute projects. PMS helps project managers and
          teams streamline processes, collaborate efficiently, and ensure
          successful project completion within set timelines and budgets.
        </Typography>
        <Row>
          <Col xs={12} md={6} className="order-md-2 d-flex">
            <img
              src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/pms1.png"
              className="img-fluid"
              alt=".."
              style={{
                margin: "auto",
                mixBlendMode: "multiply",
                objectFit: "cover",
              }}
            />
          </Col>
          <Col xs={12} md={6}>
            <Typography variant="h4" component="p" className="my-4 text-start">
              Features
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>User Interface Dashboard</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Role & Permissions Management</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>User Management</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Client Management</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Projects and its team members Management</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Milestones Management</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Manage Companies</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Manage Subscriptions</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Manage Multi Languages</strong>
            </Typography>
            <Typography variant="subtitle2" component="p" className="mb-2">
              <TaskAltIcon className="me-1 mb-1" sx={{ color: "#00A884" }} />
              <strong>Manage Application Settings</strong>
            </Typography>
          </Col>
        </Row>

        {/* <Box className="d-flex">
          <Box
            sx={{
              my: 5,
              width: { xs: "100%", md: "70%" },
              mx: "auto",
            }}
          >
            <Carousel activeIndex={index} onSelect={handleSelect}>
              <Carousel.Item>
                <img className="d-block w-100" src={img2} alt="First slide" />
                <Carousel.Caption>
                  <h3>Create Project</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={img3} alt="Second slide" />

                <Carousel.Caption>
                  <h3>Second slide label</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={img4} alt="Third slide" />

                <Carousel.Caption>
                  <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl
                    consectetur.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Box>
        </Box> */}

        <Typography variant="subtitle1" component="p" className="mt-2">
          In the context of engineering and construction, a PMS typically offers
          features and functionalities specific to the industry's requirements.
          Here are some key aspects that a PMS for engineering and construction
          might encompass:
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong> Project Planning: </strong>A PMS allows project managers to
          create project plans, define project objectives, set milestones,
          allocate resources, and estimate budgets. It facilitates the creation
          of work breakdown structures (WBS) and Gantt charts to visualize
          project timelines and dependencies.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong> Document Management: </strong> Engineering and construction
          projects involve numerous documents, including blueprints, design
          drawings, contracts, permits, and specifications. A PMS provides a
          centralized platform to store, organize, version control, and share
          these documents among project stakeholders, ensuring everyone has
          access to the latest information.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong> Collaboration and Communication: </strong>Effective
          collaboration and communication are essential for successful project
          execution. A PMS enables real-time collaboration by allowing team
          members to share updates, exchange messages, and assign tasks. It may
          also offer integrations with popular communication tools such as email
          or instant messaging platforms.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Resource Management: </strong>Engineering and construction
          projects require efficient management of various resources, such as
          personnel, equipment, and materials. A PMS helps track resource
          availability, allocate resources to specific tasks, and monitor
          resource utilization throughout the project lifecycle.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong> Cost Management: </strong>Construction projects involve
          complex cost structures, including budgets, estimates, and expenses. A
          PMS provides features to track project costs, compare actual
          expenditures against the budget, and generate reports to identify cost
          overruns or savings. It may integrate with accounting software for
          streamlined financial management.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Risk Management: </strong>Engineering and construction
          projects often face uncertainties and risks that can impact project
          outcomes. A PMS assists in identifying, assessing, and mitigating
          risks by maintaining a risk register, tracking mitigation measures,
          and enabling proactive risk management throughout the project.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong> Progress Tracking and Reporting: </strong> A PMS allows
          project managers to monitor project progress, track task completion,
          and generate reports on key performance indicators (KPIs). These
          reports provide insights into project health, milestones achieved,
          resource utilization, and overall project performance.
        </Typography>

        <Typography variant="subtitle1" component="li" className="my-2 ">
          <strong>Integration and Automation: </strong> A comprehensive PMS may
          offer integrations with other software tools commonly used in the
          engineering and construction industries, such as computer-aided design
          (CAD) software, building information modeling (BIM) platforms, and
          enterprise resource planning (ERP) systems. Automation features like
          task reminders, notifications, and data synchronization can further
          enhance productivity.
        </Typography>

        <Typography variant="subtitle1" component="p" className="mt-4">
          When selecting a PMS for engineering and construction, it's essential
          to consider the specific needs of your organization, the scale and
          complexity of your projects, and the scalability and flexibility of
          the software to accommodate future growth. It's recommended to
          evaluate different PMS options, conduct demos, and engage with vendors
          or providers to ensure the chosen system aligns with your project
          management requirements.
        </Typography>
      </Container>
    </Box>
  );
}
