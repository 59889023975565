import React from "react";

export default function SectionThree() {
  return (
      <iframe
        title="3d"
        id="svt_iframe_1679091c5a880faf6fb5e6087eb1b2dc"
        allow="accelerometer; camera; display-capture; fullscreen; geolocation; gyroscope; magnetometer; microphone; midi; xr-spatial-tracking;"
        width="100%"
        height="500px"
        //src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/VR-Tour/index.html" 
src="https://virtualtour.nulark.tech/viewer/index.php?code=a1d0c6e83f027327d8461063f4ac58a6"
      />
  );
}
