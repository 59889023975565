import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export default function App() {
  const boxRef = useRef(null);
  const [isBoxInView, setIsBoxInView] = useState(false);

  useEffect(() => {
    const boxElement = boxRef.current;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        setIsBoxInView(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust the threshold as needed
    });

    if (boxElement) {
      observer.observe(boxElement);
    }

    return () => {
      if (boxElement) {
        observer.unobserve(boxElement);
      }
    };
  }, []);

  useEffect(() => {
    const boxElement = boxRef.current;

    if (boxElement && !isBoxInView) {
      boxElement.scrollTop = 0;
    }
  }, [isBoxInView]);
  return (
    <>
      <Box
        sx={{
          clipPath: "polygon(0 0,100% 80%,100% 100%,0 100%)",
          background: "#ffd006b3",
          height: 50,
        }}
      />
      <Box
        id="box"
        ref={boxRef}
        sx={{
          py: 5,
          background: "#ffd006b3",
          height: "100vh",
          overflowY: "scroll",
          scrollBehavior: "smooth",
        }}
      >
        <Container className="py-5 px-md-5">
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Box
                className="d-flex px-md-5"
                sx={{
                  flexDirection: "column",
                  gap: 5,
                  color:"text.secondary"
                }}
              >
                <Typography
                component="h3"
                variant="h4" 
                color="text.secondary"
                >
                  <strong>Dynamic Web Solutions</strong>: Elevating Your Online Presence
                </Typography>
                <ul>
                  <li>
                    Responsive Design: Our websites are built to adapt
                    seamlessly to any device, ensuring an optimal viewing
                    experience for your visitors.
                  </li>
                  <li>
                    Intuitive Navigation: We prioritize user-friendly
                    navigation, making it easy for visitors to find what they're
                    looking for and explore your content.
                  </li>
                  <li>
                    Eye-catching Visuals: From stunning graphics to engaging
                    multimedia elements, we'll help you make a lasting
                    impression with visually striking designs.
                  </li>
                  <li>
                    Seamless Integration: Whether you need to integrate
                    third-party plugins, social media feeds, or custom features,
                    we'll streamline the process to ensure seamless
                    functionality.
                  </li>
                  <li>
                    Performance Optimization: Our team utilizes best practices
                    for web performance optimization, ensuring fast load times
                    and smooth browsing experiences.
                  </li>
                </ul>
              </Box>
            </Grid>

            <Grid item xs={12} md={5}>
              <Box
                className="h-100 pt-5"
                sx={{
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "sticky",
                    top: 50,
                  }}
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/9933427_4300578+1.svg"
                    alt=".."
                    className="img-fluid"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          clipPath: "polygon(0 0,100% 0%,100% 100%,0 0%)",
          background: "#ffd006b3",
          height: 50,
          mt: -0.03,
        }}
      />
    </>
  );
}
