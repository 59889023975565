import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const darkLogos = [
  "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Client%26Partners/PV-LOGO+1.svg",
  "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Client%26Partners/logo+1.svg",
  "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Client%26Partners/icc.svg",
  "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Client%26Partners/StAnthony.svg",
  "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Client%26Partners/adverk.svg",
  "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Client%26Partners/Optic+seychelles.svg",
];

const logoStyle = {
  width: "100px",
  height: "80px",
  margin: "0 32px",
  opacity: 0.75,
};

export default function LogoCollection() {
  return (
    <Box sx={{ py: 4, my:4 }}>
      <Typography
        component="h4"
        variant="h4"
        align="center"
        fontWeight="700"
      >
        Our Clients & Partners
      </Typography>
      <Typography
        component="p"
        variant="p"
        align="center"
        color="text.secondary"
        gutterBottom
      >
       Collaborating for Success: Building Strong Relationships in Business
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 4, opacity: 0.6 }}>
        {darkLogos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Fake company number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
