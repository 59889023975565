"use client";

import { useRef, useEffect } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { AnimationMixer } from "three";

function MeshComponent() {
  const fbxUrl = "/Hip Hop Dancing.fbx";
  const mesh = useRef(null);
  const mixer = useRef();
  const fbx = useLoader(FBXLoader, fbxUrl);

  useEffect(() => {
    mixer.current = new AnimationMixer(fbx);
    const action = mixer.current.clipAction(fbx.animations[0]);
    action.play();
    fbx.scale.set(0.04, 0.04, 0.04);
    fbx.position.set(0, -3.2, 0);
  }, [fbx]);
  useFrame((state, delta) => {
    if (mixer.current) {
      mixer.current.update(delta);
    }
  });
  return <primitive ref={mesh} object={fbx} />;
}

export default function VrModel() {
  return (
    <Canvas
      className="w-100"
      style={{
        height: "350px",
        maxHeight: "400px",
      }}
    >
      <OrbitControls />
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <MeshComponent />
    </Canvas>
  );
}
