import { Route, Routes } from "react-router-dom";
import "./App.css";
import Root from "./Route/Root";
import Footer from "./Components/Footer/Footer";
import DrawerAppBar from "./Components/Navbar/App";
function App() {
  return (
    <>
      <DrawerAppBar />
      <Routes>
        <Route>
          <Route path="*" element={<Root />} />
        </Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
